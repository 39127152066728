import React from 'react';
import { Link as RRLink, LinkProps } from 'react-router-dom';

export const LinkView: React.FC<LinkProps> = ({ ...props }) => {
  return (
    <RRLink
      css={{
        cursor: 'pointer',
        display: 'block',
        textDecoration: 'none',
        '&:active': {
          opacity: 0.4,
          color: 'inherit',
        },
        '&:visited': {
          color: 'inherit',
        },
      }}
      {...props}
    />
  );
};
