import {
  createSlice,
  createEntityAdapter,
  PayloadAction,
  EntityState,
} from '@reduxjs/toolkit';
import { DataPartner } from 'core/models';
import { ProcessStatus } from 'states';

export const entityAdapter = createEntityAdapter<DataPartner>({
  selectId: (model) => model.id,
  sortComparer: (a, b) => a.id.localeCompare(b.id),
});

interface DataPartnerState extends EntityState<DataPartner> {
  status: ProcessStatus;
}

const initialState: DataPartnerState = entityAdapter.getInitialState({
  status: 'idle',
});

const slice = createSlice({
  name: 'dataPartner',
  initialState,
  reducers: {
    resetStatus(state) {
      state.status = 'idle';
    },
    list(state) {
      state.status = 'loading';
    },
    listSuccess(state, action: PayloadAction<DataPartner[]>) {
      entityAdapter.addMany(state, action.payload);
      state.status = 'success';
    },
    listFailure(state) {
      state.status = 'failure';
    },
  },
});

export const actions = slice.actions;
export default slice;
