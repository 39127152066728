import { cloneDeep } from 'lodash';
import { Consent, Permission } from 'core/models';

export class ConsentService {
  static isConsentedAny(consent: Consent): boolean {
    return !!Object.values(consent.permissions.dataPartners).find(
      (permission) => permission === 'Yes',
    );
  }

  static isConsented(consent: Consent, dataPartnerId: string): boolean {
    return consent.permissions.dataPartners[dataPartnerId] === 'Yes';
  }

  static setPermission(
    consent: Consent,
    dataPartnerId: string,
    permission: Permission,
  ): Consent {
    const _consent = cloneDeep(consent);
    if (dataPartnerId in _consent.permissions.dataPartners) {
      _consent.permissions.dataPartners[dataPartnerId] = permission;
    }
    return _consent;
  }
}
