import { Action } from '@reduxjs/toolkit';
import { concat, from, Observable, of, throwError } from 'rxjs';
import { catchError, filter, mergeMap } from 'rxjs/operators';
import { getApiClient } from 'core/api';
import { ConsentApi } from 'core/api/consent';
import { AuthService } from 'core/services';
import { actions } from '../slice';
import { Consent } from 'core/models';

const removeUserId = (consent: Consent): Omit<Consent, 'userId'> => {
  const { userId: _removed, ...res } = consent;
  return res;
};

export const updateEpic = (action$: Observable<Action>) =>
  action$.pipe(
    filter(actions.update.match),
    mergeMap(({ payload: { consent } }) =>
      from(
        new ConsentApi(
          getApiClient({ idToken: AuthService.currentIdToken() }),
        ).post(removeUserId(consent)),
      ).pipe(
        mergeMap(() =>
          concat(
            of(actions.updateSuccess({ consent })),
            of(actions.resetUpdateStatus()),
          ),
        ),
        catchError((error) =>
          concat(
            of(actions.updateFailure()),
            of(actions.resetUpdateStatus()),
            throwError(error),
          ),
        ),
      ),
    ),
  );
