import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { combineEpics, createEpicMiddleware } from 'redux-observable';
import { concat, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ApiError } from 'core/api';
import { AuthService } from 'core/services';
import i18n from 'i18n';
import * as appState from './app';
import * as accountState from './account';
import * as authState from './auth';
import * as consentState from './consent';
import * as dataPartnerState from './dataPartner';
import * as faqState from './faq';
import * as advertiserState from './advertiser';
import * as newsState from './news';
import * as publisherMediaState from './publisherMedia';

const rootReducers = () =>
  combineReducers({
    app: appState.reducer,
    account: accountState.reducer,
    auth: authState.reducer,
    consent: consentState.reducer,
    dataPartner: dataPartnerState.reducer,
    faq: faqState.reducer,
    advertiser: advertiserState.reducer,
    news: newsState.reducer,
    publisherMedia: publisherMediaState.reducer,
  });

const rootEpics = () => (action$: any, store$: any, dependency: any) =>
  combineEpics<any>(
    ...appState.epics,
    ...accountState.epics,
    ...authState.epics,
    ...consentState.epics,
    ...dataPartnerState.epics,
    ...faqState.epics,
    ...advertiserState.epics,
    ...newsState.epics,
    ...publisherMediaState.epics,
  )(action$, store$, dependency).pipe(
    catchError((error, source) => {
      console.debug('[epic global error handler]', error);
      // TODO: Send error to monitoring service
      const actions = [];

      if (error instanceof ApiError) {
        if (error.statusCode === 401 || error.statusCode === 403) {
          AuthService.removeSession();
          actions.push(of(authState.actions.disconnectSession()));
        } else if (error.statusCode === 408) {
          toast.warn(i18n.t('errors.requestTimeout'));
        } else if (error.statusCode >= 400 && error.statusCode < 500) {
          actions.push(of(appState.actions.redirect({ url: '/client-error' })));
        } else if (error.statusCode >= 500) {
          actions.push(of(appState.actions.redirect({ url: '/server-error' })));
        }
      }

      return concat(...actions, source);
    }),
  );

export type RootState = ReturnType<ReturnType<typeof rootReducers>>;

export const initStore = (preloadedState?: RootState) => {
  const epicMiddleware = createEpicMiddleware();

  const store = configureStore({
    preloadedState,
    reducer: rootReducers(),
    middleware: [epicMiddleware],
  });

  epicMiddleware.run(rootEpics());
  return store;
};

export type AppDispatch = ReturnType<typeof initStore>['dispatch'];
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
