const translation = {
  global: {
    backToHome: `ホームへ戻る`,
    backToTop: `トップページへ戻る`,
    bankAccountNumber: `口座番号`,
    cancel: `キャンセル`,
    close: `閉じる`,
    contact: `お問い合わせ`,
    dataDividend: `データ配当金`,
    documentTitle: `{{title}} | Themix Personal`,
    emailAddress: `メールアドレス`,
    faq: `よくあるご質問`,
    home: `ホーム`,
    license: `ライセンス`,
    login: `ログイン`,
    logout: `ログアウト`,
    menu: `メニュー`,
    name: `氏名`,
    point: `ポイント`,
    privacyPolicy: `プライバシーポリシー`,
    showMore: `もっと見る`,
    termsOfUse: `利用規約`,
    title: `Themix Personal`,
    version: `バージョン`,
    yen: `円`,
  },
  errors: {
    requestTimeout: `タイムアウトしました。`,
  },
  ui: {
    pages: {
      ConsentRequested: {
        title: 'データ提供開始 - 受付完了',
        message: `データ提供開始の申込みを受付ました。\n申込日翌日の10:30頃からデータ提供を開始します。`,
        annotation: `データ提供開始申込日中にデータ提供停止のお申込をいただくことで、データ提供開始申込がキャンセルされます。`,
      },
      ContactComplete: {
        title: `送信完了`,
        message: `お問い合わせが完了いたしました。`,
      },
      ContactConfirm: {
        title: `送信内容を確認`,
        contentTitle: `お問い合わせ内容`,
        submit: `送信`,
      },
      Contact: {
        title: `お問い合わせ`,
        contentTitle: `お問い合わせ内容`,
        submit: `内容を確認する`,
      },
      DataPartnerList: {
        title: `データパートナー一覧`,
        description: `あなたが許可したデータプロバイダによって、その広告主の広告が表示されることがあります。\nあなたの情報に基づいてデータを使用している広告主を確認し、このデータに基づく広告表示を許可するかどうか選択できます。`,
      },
      DataPartnerDetail: {
        title: `データパートナー詳細`,
        dataFieldListTitle: `データ項目一覧`,
        detaFieldListHelp: `このデータパートナーが保持しているアナタのデータです。`,
        consent: `データ提供を開始する`,
        dissent: `すべてのデータ提供を停止する`,
        consentDialogTitle: `申込日翌日の10:30頃からデータ提供を開始します。`,
        consentDialogOK: `提供する`,
        consentDialogCancel: `キャンセル`,
        dissentDialogTitle: `データ提供の停止は、申込日翌日の10:30頃までに行います。`,
        dissentDialogOK: `提供を停止する`,
        dissentDialogCancel: `キャンセル`,
        defaultDescription: `住信SBIネット銀行が保存しているお客さまの以下のデータを連携します。\n\n・お客さまの属性情報（氏名・住所・電話番号・勤務先など）\n・当社サービスのご利用状況(各商品の利用有無、預金残高など)\n・メールアドレス\n・15歳未満のお客さまはデータ提供の状態にかかわらず、住信SBIネット銀行からのデータ連携は行いません。`,
      },
      DeleteAccount: {
        title: `退会`,
        confirmation: `退会に関する上記事項を確認し、理解しました。`,
        message: `ご利用ありがとうございました。法令等で必要とされる範囲を除き、当社が保有するお客さまのデータは適切に削除します。`,
        submit: `退会する`,
      },
      DeleteAccountComplete: {
        title: `退会完了`,
        message: `退会処理が完了しました。`,
      },
      DissentRequested: {
        title: 'データ提供停止 - 受付完了',
        message: `データ提供停止の申込みを受付ました。\n申込日翌日の10:30頃までに、データ提供の停止を行います。`,
        annotation: `データ提供停止申込日中にデータ提供開始のお申込をいただくことで、データ提供停止申込がキャンセルされます。`,
      },
      Error: {
        contact: `お問合せはこちら`,
        NotFound: {
          title: `ページが見つかりません`,
          message: `アクセスしようとしたページは、変更、削除されたか、または現在利用できない可能性があります。\n恐れ入りますが、再度トップページからアクセスしてください。`,
        },
        ClientError: {
          title: `クライアントエラー`,
          message: `予期しないエラーが発生しました。`,
        },
        ServerError: {
          title: `サーバーエラー`,
          message: `処理が正常に完了しませんでした。恐れ入りますが、再度お手続きください。\n引き続きこのメッセージが表示される場合は、下記のリンクよりお問合せください。`,
        },
      },
      FAQ: {
        title: `よくある質問`,
      },
      FAQDetail: {
        back: `よくある質問一覧`,
        title: `よくある質問`,
      },
      Home: {
        adPreviewHelp: `あなたの情報に基づいて表示されている広告を確認できます。`,
        adPreviewTitle: `広告プレビュー`,
        adPreviewEmptyMessage: `現在、あなたの情報に基いて表示された広告はありません。`,
        advertiserListTitle: `広告主一覧`,
        consentTitle: `あなたが利用しているサービスから提供されているデータ`,
        consentableDataPartner: `（停止中）`,
        consentingDataPartner: `（提供中）`,
        dataUsageTitle: `データ提供状況`,
        dataUsageHelp: `※Themix Personalへのデータ提供状況を確認、変更できます。`,
        mediaListTitle: `連携サービス`,
        notificationHelp: `Themix Personal運営からのお知らせです`,
        notificationTitle: `お知らせ`,
        Header: {
          worthAbout: `相当`,
          totalAmount: `これまでの獲得総額`,
        },
      },
      Information: {
        title: `お知らせ`,
      },
      NewsDetail: {
        title: `お知らせ`,
      },
      Redemption: {
        title: `データ配当金照会`,
        noData: `データ利用に対する配当金はございません`,
      },
      Settings: {
        withdrawal: `退会`,
        logoutConfirmDialogTitle: `ログアウトします。\nよろしいですか？`,
      },
      SignUp: {
        title: `サインアップ`,
        about: `詳しくはこちら`,
        accountSetting: `アカウント設定`,
        agree: `に同意`,
        annotation1: `※Themix Personal(テミクス パーソナル)は、お客さまの個人情報からライフスタイルに合った広告をご利用のSNS等に表示させていただくサービスです。住信SBIネット銀行のグループ会社である株式会社テミクス・データが運営しています。`,
        annotation2: `※株式会社テミクス・データの「Themix Personal利用規約」および「プライバシー・ポリシー」に同意いただくことで、お客さまのパーソナルデータが株式会社テミクス・データに提供されます。`,
        annotation3: `※ご利用開始後も、テミクス パーソナルの画面より、いつでもデータ提供の設定を変更できます。`,
        ssnbUsername: `住信SBIネット銀行のユーザーネーム`,
        ssnbWebLoginPassword: `Webログインパスワード`,
        submit: `同意して利用する`,
        unauthorizedError1: `認証処理が正常に完了しませんでした。`,
        unauthorizedError2: `こちら`,
        unauthorizedError3: `から再度お試しください。`,
        accountAlreadyInUse1: `お客さまは、すでに登録済みです。`,
        accountAlreadyInUse2: `こちら`,
        accountAlreadyInUse3: `からご利用ください。`,
      },
      Start: {
        title: `利用開始`,
        about: `詳しくはこちら`,
        submit: `Themix Personalを利用する`,
        caveat: `※住信SBIネット銀行のログイン画面に遷移します。`,
        annotation1: `※Themix Personal(テミクス パーソナル)は、お客さまの個人情報からライフスタイルに合った広告をご利用のSNS等に表示させていただくサービスです。住信SBIネット銀行のグループ会社である株式会社テミクス・データが運営しています。`,
        annotation2: `\n■利用を開始する場合\n※住信SBIネット銀行の「個人情報のお取扱いについて」、株式会社テミクス・データの「Themix Personal利用規約」および「プライバシー・ポリシー」に同意いただくことで、お客さまのパーソナルデータが株式会社テミクス・データに提供されます。`,
        annotation3: `※ご利用開始後も、テミクス パーソナルの画面より、いつでもデータ提供の設定を変更できます。`,
      },
    },
  },
  validation: {
    required: `入力してください`,
    email: `メールアドレスを正しく入力してください`,
  },
};

export default translation;
