import React from 'react';
import styled from '@emotion/styled';
import { mediaQueries } from 'ui/theme';
import { BottomNavigation, NavigationItem, SideNavigation } from '..';

const Container = styled.div({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
});

const SideMenuLayout = styled.div({
  display: 'flex',
  flexDirection: 'row',
  height: '100%',
  boxSizing: 'border-box',
  [mediaQueries.lg]: {
    margin: '0 auto',
    paddingTop: '32px',
    width: 960,
  },
});

const SideMenuLeftContainer = styled.div({
  display: 'none',
  marginRight: 24,
  width: 180,
  [mediaQueries.lg]: {
    display: 'block',
  },
});

const SideMenuRightContainer = styled.div({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
});

const BottomTabContainer = styled.div({
  [mediaQueries.lg]: {
    display: 'none',
  },
});

const Content = styled.div({
  flex: 1,
  overflowY: 'scroll',
});

export interface LayoutProps {
  activeNavigationItem?: NavigationItem;
  header?: React.ReactNode;
  showNavigator?: boolean;
}

export const Layout: React.FC<LayoutProps> = ({
  activeNavigationItem,
  children,
  header,
  showNavigator,
}) => (
  <Container>
    <SideMenuLayout>
      {showNavigator && (
        <SideMenuLeftContainer>
          <SideNavigation active={activeNavigationItem} />
        </SideMenuLeftContainer>
      )}
      <SideMenuRightContainer>
        <div>{header}</div>
        <Content>{children}</Content>
        {showNavigator && (
          <BottomTabContainer>
            <BottomNavigation active={activeNavigationItem} />
          </BottomTabContainer>
        )}
      </SideMenuRightContainer>
    </SideMenuLayout>
  </Container>
);
