import * as inflector from 'inflected';
import { createServer, RestSerializer } from 'miragejs';
import config from 'core/config';
import { factories } from './factories';
import { models } from './models';
import * as routes from './routes';
import { seeds } from './seeds';

interface ServerOptions {
  environment?: 'development' | 'test';
  trackRequests?: boolean;
}

const defaultOptions: ServerOptions = {
  environment: 'test',
  trackRequests: false,
};

// Mirage can't pluralize a word 'publisherMedia'.
// So it need to tell mirage how to pluralize word 'publisherMedia'.
// Customize inflections are described in https://miragejs.com/docs/advanced/customizing-inflections/
// `inflections` is not exposed from the type definition of inflected npm package.
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
inflector.inflections('en', function (inflect) {
  inflect.irregular('publisherMedia', 'publisherMedias');
});

export function startMirage(options?: ServerOptions) {
  return createServer({
    ...defaultOptions,
    ...options,

    serializers: {
      application: RestSerializer,
      consent: RestSerializer.extend({
        root: false,
        embed: true,
        attrs: ['userId', 'permissions'],
      }),
      faqCategory: RestSerializer.extend({ root: false, embed: true }),
      news: RestSerializer.extend({ root: false, embed: true }),
    },

    models,
    factories,
    seeds,
    routes() {
      this.urlPrefix = config.neoXApiUrl;
      routes.authRoutes(this);
      routes.accountRoutes(this);
      routes.listApisRoutes(this);
      routes.consentManagementRoutes(this);

      // Enable following code if you want to get news file from real server
      // this.passthrough(config.faqJsonFileUrl);
      // this.passthrough(config.newsJsonFileUrl);
    },
  });
}

export type Server = ReturnType<typeof startMirage>;
