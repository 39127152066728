import styled from '@emotion/styled';
import { Color, FontSize } from 'ui/theme';

export const Text = styled.div<{
  color?: Color;
  size?: FontSize | number;
  bold?: boolean;
  lineHeight?: number;
}>(({ theme, color, size, bold, lineHeight }) => ({
  color: theme.color[color ?? 'textPrimary'],
  fontSize: typeof size === 'number' ? size : theme.fontSize[size ?? 'md'],
  fontWeight: bold ? 'bold' : undefined,
  lineHeight,
  whiteSpace: 'pre-line',
}));
