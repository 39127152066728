import React from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import { Advertiser, DataPartner, News, PublisherMedia } from 'core/models';
import {
  AboutCarousel,
  Layout,
  Link,
  Section,
  SectionTitle,
  Spacer,
  Text,
} from 'ui/components/shared';
import { ExternalLink } from './ExternalLink';
import { Header } from './Header';
import { NewsSection } from './NewsSection';
import { ListItem } from './ListItem';
import { Theme } from 'ui/theme';

const mainStyle = css({
  padding: 16,
});

export interface HomeProps {
  consented: boolean;
  advertisers: Advertiser[];
  dataPartners: DataPartner[];
  newsList: News[];
  publisherMedias: PublisherMedia[];
}

export const HomePresenter: React.VFC<HomeProps> = ({
  consented,
  advertisers,
  dataPartners,
  newsList,
  publisherMedias,
}) => {
  const { t } = useTranslation();

  return (
    <Layout activeNavigationItem="home" showNavigator>
      <Header />
      <main css={mainStyle}>
        {newsList.length > 0 && (
          <>
            <NewsSection newsList={newsList} />
            <Spacer space={16} />
          </>
        )}
        <Spacer space={16} />
        <Section>
          <SectionTitle title={t('ui.pages.Home.dataUsageTitle')} />
          <Spacer space={8} />
          <Text
            css={(theme: Theme) => ({
              color: theme.color.primary,
              size: theme.fontSize.sm,
            })}
          >
            {t('ui.pages.Home.dataUsageHelp')}
          </Text>
          <Spacer space={16} />
          {dataPartners.map((dataPartner) => (
            <ListItem
              key={dataPartner.id}
              title={
                <Link
                  color="textPrimary"
                  size="sm"
                  to={`/partners/${dataPartner.id}`}
                >
                  {dataPartner.displayName}
                  {t(
                    `ui.pages.Home.${
                      consented
                        ? 'consentingDataPartner'
                        : 'consentableDataPartner'
                    }`,
                  )}
                </Link>
              }
            />
          ))}
          <Spacer space={16} />
          <SectionTitle title={t('ui.pages.Home.mediaListTitle')} />
          <Spacer space={16} />
          {publisherMedias.map((media) => (
            <ListItem
              key={media.id}
              title={<ExternalLink url={media.url}>{media.name}</ExternalLink>}
            />
          ))}
        </Section>
      </main>
    </Layout>
  );
};
