import React from 'react';
import ReactModal from 'react-modal';
import { ConfirmDialogPresenter } from './presenter';

export interface ConfirmDialogProps {
  cancelId?: string;
  cancelTitle?: string;
  message?: string;
  okId?: string;
  okTitle?: string;
  onCancel?: () => void;
  onOK?: () => void;
  title?: string;
  visible: boolean;
}

export const ConfirmDialog: React.VFC<ConfirmDialogProps> = ({
  cancelId,
  cancelTitle,
  message,
  okId,
  okTitle,
  onCancel,
  onOK,
  title,
  visible,
}) => {
  return (
    <ReactModal
      ariaHideApp={false}
      isOpen={visible}
      onRequestClose={onCancel}
      shouldCloseOnOverlayClick={false}
      style={{
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(255, 255, 255, 0.75)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
        content: {
          position: 'unset',
          inset: 'unset',
          border: 'none',
          padding: 'unset',
        },
      }}
    >
      <ConfirmDialogPresenter
        cancelId={cancelId}
        cancelTitle={cancelTitle}
        message={message}
        okId={okId}
        okTitle={okTitle}
        onCancel={onCancel}
        onOK={onOK}
        title={title}
      />
    </ReactModal>
  );
};
