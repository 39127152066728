import React from 'react';
import { useTranslation } from 'react-i18next';
import { MdChevronLeft } from 'react-icons/md';
import { FAQ } from 'core/models';
import { Layout, Link, PageHeader, Section, Text } from 'ui/components/shared';
import { Theme } from 'ui/theme';

export const FAQDetailPresenter: React.VFC<{
  faq?: FAQ;
  showNavigator?: boolean;
}> = ({ faq, showNavigator = false }) => {
  const { t } = useTranslation();
  return (
    <Layout
      activeNavigationItem="settings"
      header={<PageHeader title={t('ui.pages.FAQDetail.title')} />}
      showNavigator={showNavigator}
    >
      <Link
        to={`/faq`}
        css={{ display: 'flex', alignItems: 'center', padding: '0 16px' }}
      >
        <MdChevronLeft
          css={(theme: Theme) => ({
            color: theme.color.primary,
            fontSize: theme.iconSize.md,
          })}
        />
        <Text color={'primary'}>{t('ui.pages.FAQDetail.back')}</Text>
      </Link>
      <div css={{ padding: 16 }}>
        <Section>
          <Text bold css={{ marginBottom: 16 }} lineHeight={1.4}>
            {faq?.question}
          </Text>
          <Text lineHeight={1.4}>{faq?.answer}</Text>
        </Section>
      </div>
    </Layout>
  );
};
