import React from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorPresenter } from '../presenter';

export const ClientErrorPresenter: React.VFC<{
  showNavigator?: boolean;
}> = ({ showNavigator = false }) => {
  const { t } = useTranslation();
  return (
    <ErrorPresenter
      title={t('ui.pages.Error.ClientError.title')}
      message={t('ui.pages.Error.ClientError.message')}
      showNavigator={showNavigator}
    />
  );
};
