import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'states/store';

const selectAuth = (state: RootState) => state.auth;

const selectError = createSelector(selectAuth, (state) => state.error);

const selectStatus = createSelector(selectAuth, (state) => state.status);

const selectAuthenticated = createSelector(
  selectAuth,
  (state) => state.authenticated,
);

export const selectors = {
  selectError,
  selectStatus,
  selectAuthenticated,
};
