import styled from '@emotion/styled';
import { touchableOpacityStyle } from 'ui/styles';
import { Color } from 'ui/theme';

export const Button = styled.button<{
  color?: Color;
  disabled?: boolean;
}>(({ color, disabled, theme }) => [
  touchableOpacityStyle,
  {
    display: 'flex',
    alignItems: 'center',
    backgroundColor:
      theme.color[disabled ? 'buttonDisabled' : color ?? 'primary'],
    justifyContent: 'center',
    boxSizing: 'border-box',
    height: 56,
    width: '100%',
    border: 'none',
    borderRadius: 6,
    outline: 'none',
    appearance: 'none',
    padding: '16px 24px',
    color: theme.color[disabled ? 'buttonTextDisabled' : 'white'],
    fontSize: 14,
    fontWeight: 'bold',
    textDecoration: 'none',
  },
]);
