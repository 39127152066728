import { Action } from '@reduxjs/toolkit';
import { concat, from, Observable, of, throwError } from 'rxjs';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';
import { ApiError } from 'core/api';
import { SignUpApi, SSORequest } from 'core/api/auth';
import { AuthService } from 'core/services';
import { actions } from '../slice';

const signUp = async (request: SSORequest): Promise<void> => {
  const { idToken, userId } = await new SignUpApi().signUp(request);
  AuthService.saveSession({ idToken, userId });
};

export const signUpEpic = (action$: Observable<Action>) =>
  action$.pipe(
    filter(actions.signUp.match),
    mergeMap(({ payload }) =>
      from(signUp(payload)).pipe(
        map(() => actions.signUpSuccess()),
        catchError((error) => {
          if (
            error instanceof ApiError &&
            [401, 409].includes(error.statusCode)
          ) {
            return concat(
              of(actions.signUpFailure({ error })),
              of(actions.resetStatus()),
            );
          }
          return concat(
            of(actions.signUpFailure()),
            of(actions.resetStatus()),
            throwError(error),
          );
        }),
      ),
    ),
  );
