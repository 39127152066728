import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  PageHeader,
  Layout,
  Link,
  Section,
  Spacer,
  Text,
} from 'ui/components/shared';

export const DeleteAccountCompletePresenter: React.VFC = () => {
  const { t } = useTranslation();

  return (
    <Layout
      activeNavigationItem="settings"
      header={<PageHeader title={t('ui.pages.DeleteAccountComplete.title')} />}
    >
      <div css={{ padding: 16 }}>
        <Section>
          <Text>{t('ui.pages.DeleteAccountComplete.message')}</Text>
        </Section>
        <Spacer space={32} />
        <div
          css={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Link bold color="textPrimary" to="/">
            {t('global.backToTop')}
          </Link>
        </div>
      </div>
    </Layout>
  );
};
