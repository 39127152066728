import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { ThemeProvider } from '@emotion/react';
import { AppStyle, Toast } from 'ui/components/shared';
import { AppRoutes } from 'AppRoutes';
import { initStore } from 'states/store';
import { useBoot, useRedirect } from 'ui/hooks';
import theme from 'ui/theme';
import 'i18n';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import 'react-toastify/dist/ReactToastify.css';
import 'rc-tooltip/assets/bootstrap.css';
import 'App.css';
import 'animate.css';

const store = initStore();

export const App: React.FC = () => {
  useBoot();
  const { redirectTo } = useRedirect();

  if (redirectTo) {
    return <Navigate to={redirectTo} />;
  }

  return (
    <>
      <AppStyle />
      <AppRoutes />
      <Toast />
    </>
  );
};

const AppProvider: React.FC = () => (
  <HelmetProvider>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </Provider>
  </HelmetProvider>
);

export default AppProvider;
