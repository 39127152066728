import { Action } from '@reduxjs/toolkit';
import { from, Observable, of, concat, throwError } from 'rxjs';
import { filter, mergeMap, catchError } from 'rxjs/operators';
import { QUERY_PARAM_CRYPTOGRAM } from 'core/const';
import { ApiError, getApiClient } from 'core/api';
import { SignInApi, SignOutApi } from 'core/api/auth';
import { AuthService } from 'core/services';
import { actions } from '../slice';

const boot = async ({
  cryptogram,
  pathname,
}: {
  cryptogram?: string;
  pathname: string;
}): Promise<{
  authenticated: boolean;
  redirectTo?: string;
}> => {
  let authenticated = !!AuthService.getSession();
  let redirectTo: string | undefined = undefined;

  if (cryptogram && pathname === '/') {
    try {
      if (authenticated) {
        await new SignOutApi(
          getApiClient({ idToken: AuthService.currentIdToken() }),
        ).signOut();
        AuthService.removeSession();
        authenticated = false;
        window.location.href = `/?${QUERY_PARAM_CRYPTOGRAM}=${encodeURIComponent(
          cryptogram,
        )}`;
      } else {
        const { idToken, userId } = await new SignInApi().signIn({
          type: 'ssnb_sso',
          cryptogram,
        });
        AuthService.saveSession({ idToken, userId });
        authenticated = true;
      }
    } catch (e) {
      if (e instanceof ApiError) {
        if (e.statusCode === 401 && e.message === 'Invalid Credentials') {
          redirectTo = `/start`;
        } else if (e.statusCode === 401 && e.message === 'Not signed up') {
          redirectTo = `/signup?${QUERY_PARAM_CRYPTOGRAM}=${encodeURIComponent(
            cryptogram,
          )}`;
        } else {
          throw e;
        }
      } else {
        throw e;
      }
    }
  }
  return { authenticated, redirectTo };
};

export const bootEpic = (action$: Observable<Action>) =>
  action$.pipe(
    filter(actions.boot.match),
    mergeMap(({ payload }) =>
      from(boot(payload)).pipe(
        mergeMap((result) =>
          concat(of(actions.bootSuccess(result)), of(actions.resetStatus())),
        ),
        catchError((error) =>
          concat(
            of(actions.bootFailure()),
            of(actions.resetStatus()),
            throwError(error),
          ),
        ),
      ),
    ),
  );
