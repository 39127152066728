import axios, { AxiosRequestHeaders, AxiosInstance } from 'axios';
import config from 'core/config';

interface ApiClientOption {
  baseURL?: string;
  timeout?: number;
  idToken?: string;
}

const defaultApiClientOptions: ApiClientOption = {
  baseURL: config.neoXApiUrl,
  timeout: 5000,
};

export const getApiClient = (options?: ApiClientOption): AxiosInstance => {
  const opt = { ...defaultApiClientOptions, ...options };

  const headers: AxiosRequestHeaders = {};
  if (opt?.idToken) {
    headers['Authorization'] = `Bearer ${opt?.idToken}`;
  }

  return axios.create({
    baseURL: opt?.baseURL,
    timeout: opt?.timeout,
    headers,
  });
};

export class ApiClient {
  protected _client: AxiosInstance;
  constructor(client?: AxiosInstance) {
    this._client = typeof client === 'undefined' ? getApiClient() : client;
  }
}
