import React from 'react';
import { MdCheckBox, MdCheckBoxOutlineBlank } from 'react-icons/md';
import { Theme } from 'ui/theme';

export interface CheckboxProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: React.ReactNode;
}

export const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
  function Checkbox({ label, onChange, ...props }, ref) {
    const [checked, setChecked] = React.useState(props.checked);

    const handleChange = React.useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange?.(event);
        setChecked(event.target.checked);
      },
      [onChange],
    );

    return (
      <div css={{ display: 'inline-flex', position: 'relative' }}>
        <input
          css={(theme: Theme) => ({
            position: 'absolute',
            opacity: 0,
            margin: 0,
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            width: theme.iconSize.md,
            height: theme.iconSize.md,
          })}
          type="checkbox"
          ref={ref}
          onChange={handleChange}
          {...props}
        />
        {checked ? (
          <MdCheckBox
            css={(theme: Theme) => ({
              color: theme.color.primary,
              fontSize: theme.iconSize.md,
            })}
          />
        ) : (
          <MdCheckBoxOutlineBlank
            css={(theme: Theme) => ({
              color: theme.color.primary,
              fontSize: theme.iconSize.md,
            })}
          />
        )}
        {label && (
          <div
            css={{
              display: 'inline-flex',
              alignItems: 'center',
              marginLeft: 8,
            }}
          >
            {label}
          </div>
        )}
      </div>
    );
  },
);
