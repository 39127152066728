import React from 'react';
import { MdLink } from 'react-icons/md';
import { css } from '@emotion/react';
import { DataPartner } from 'core/models';
import { Section, Spacer, Text } from 'ui/components/shared';
import { Theme } from 'ui/theme';
import { useTranslation } from 'react-i18next';
const styles = {
  horizontal: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  }),
};

export const DataPartnerSection: React.VFC<{ dataPartner: DataPartner }> = ({
  dataPartner,
}) => {
  const { t } = useTranslation();
  return (
    <Section>
      <div css={styles.horizontal}>
        <img
          css={(theme: Theme) => ({
            marginRight: 4,
            width: theme.iconSize.lg,
            height: theme.iconSize.lg,
          })}
          alt={dataPartner.name}
          src={dataPartner.logoUrl}
        />
        <Text size="lg" bold>
          {dataPartner.displayName}
        </Text>
      </div>
      {!!dataPartner.url && (
        <>
          <Spacer space={8} />
          <a
            css={[styles.horizontal, { textDecoration: 'none' }]}
            href={dataPartner.url}
            target="_blank"
            rel="noreferrer"
          >
            <MdLink
              css={(theme: Theme) => ({
                marginRight: 4,
                color: theme.color.textSecondary,
                fontSize: 18,
              })}
            />
            <Text color="textSecondary" size="sm">
              {dataPartner.url}
            </Text>
          </a>
        </>
      )}
      {dataPartner.description ? (
        <>
          <Spacer space={16} />
          <Text>{dataPartner.description}</Text>
        </>
      ) : (
        <>
          <Spacer space={16} />
          <Text>{t('ui.pages.DataPartnerDetail.defaultDescription')}</Text>
        </>
      )}
    </Section>
  );
};
