import Axios from 'axios';
import { ApiClient } from '../apiClient';
import { ApiError } from '../errors';

export interface SsoSignInRequest {
  type: 'ssnb_sso';
  cryptogram: string;
}

export interface GeneralSignInRequest {
  type: 'ssnb_general';
  username: string;
  password: string;
}

export type SignInRequest = SsoSignInRequest | GeneralSignInRequest;

export interface SignInResponse {
  userId: string;
  idToken: string;
  message: string;
}

export class AccountApi extends ApiClient {
  async deleteAccount(): Promise<void> {
    try {
      await this._client.delete('/user/deleteme');
    } catch (e) {
      console.error(e);
      if (Axios.isAxiosError(e)) {
        throw new ApiError(e.response?.status);
      }
      throw e;
    }
  }
}
