import css from '@emotion/css';
import React from 'react';
import { MdChevronRight, MdOpenInNew } from 'react-icons/md';
import { Link as RRLink } from 'react-router-dom';
import { Text } from 'ui/components/shared';
import { Theme } from 'ui/theme';

const containerStyle = css({
  display: 'block',
  padding: '16px 0',
});

const touchableContainerSignupStyle = css([
  {
    display: 'block',
    cursor: 'pointer',
    textDecoration: 'none',
    '&:active': {
      opacity: 0.4,
      color: 'inherit',
    },
    '&:visited': {
      color: 'inherit',
    },
  },
]);

const touchableContainerStyle = css([
  containerStyle,
  {
    cursor: 'pointer',
    textDecoration: 'none',
    '&:active': {
      opacity: 0.4,
      color: 'inherit',
    },
    '&:visited': {
      color: 'inherit',
    },
  },
]);

const itemStyle = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  minHeight: 24,
});

export const ActionItem: React.VFC<{
  onClick?: () => void;
  title: string;
  chevron: boolean;
}> = ({ onClick, title, chevron }) => {
  return (
    <div css={touchableContainerStyle} onClick={onClick}>
      <div css={itemStyle}>
        <Text bold css={{ textAlign: 'center' }}>
          {title}
        </Text>
        {chevron && (
          <MdChevronRight
            css={(theme: Theme) => ({
              color: theme.color.black60,
              fontSize: theme.iconSize.md,
            })}
          />
        )}
      </div>
    </div>
  );
};

export const LabelItem: React.VFC<{
  title: string;
}> = ({ title }) => {
  return (
    <div css={containerStyle}>
      <div css={itemStyle}>
        <Text bold css={{ textAlign: 'center' }}>
          {title}
        </Text>
      </div>
    </div>
  );
};

export const LinkItem: React.VFC<{ link: string; title: string }> = ({
  link,
  title,
}) => {
  return (
    <RRLink css={touchableContainerStyle} to={link}>
      <div css={itemStyle}>
        <Text bold css={{ textAlign: 'center' }}>
          {title}
        </Text>
        <MdChevronRight
          css={(theme: Theme) => ({
            color: theme.color.black60,
            fontSize: theme.iconSize.md,
          })}
        />
      </div>
    </RRLink>
  );
};

export const ExternalLinkItem: React.VFC<{ link: string; title: string }> = ({
  link,
  title,
}) => {
  return (
    <a
      css={touchableContainerStyle}
      href={link}
      target="_blank"
      rel="noreferrer"
    >
      <div css={itemStyle}>
        <Text bold css={{ textAlign: 'center' }}>
          {title}
        </Text>
        <MdOpenInNew
          css={(theme: Theme) => ({
            color: theme.color.black60,
            fontSize: theme.iconSize.md,
          })}
        />
      </div>
    </a>
  );
};

export const SignupExternalLinkItem: React.VFC<{ link: string; title: string }> = ({
                                                                               link,
                                                                               title,
                                                                             }) => {
  return (
      <a
          css={touchableContainerSignupStyle}
          href={link}
          target="_blank"
          rel="noreferrer"
      >
        <div css={itemStyle}>
          <Text bold css={(theme: Theme) => ({ textAlign: 'center', color: theme.color.primary })}>
            {title}
          </Text>
          <MdOpenInNew
              css={(theme: Theme) => ({
                color: theme.color.primary,
                fontSize: theme.iconSize.md,
              })}
          />
        </div>
      </a>
  );
};
