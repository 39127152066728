import React from 'react';
import { useTranslation } from 'react-i18next';
import { MdChevronRight } from 'react-icons/md';
import { FAQCategory } from 'core/models';
import {
  Divider,
  Layout,
  LinkView,
  PageHeader,
  Section,
  SectionTitle,
  Spacer,
  Text,
} from 'ui/components/shared';
import { Theme } from 'ui/theme';

export const FAQPresenter: React.VFC<{
  faqs: FAQCategory[];
  showNavigator?: boolean;
}> = ({ faqs, showNavigator = false }) => {
  const { t } = useTranslation();
  return (
    <Layout
      activeNavigationItem="settings"
      header={<PageHeader title={t('ui.pages.FAQ.title')} />}
      showNavigator={showNavigator}
    >
      <div css={{ padding: 16 }}>
        {faqs.map((category, i) => (
          <React.Fragment key={category.id}>
            {i > 0 && <Spacer space={16} />}
            <Section>
              <SectionTitle title={category.category} />
              <Spacer space={16} />
              {category.faqs.map((faq) => (
                <div key={faq.id} css={{ paddingTop: 16 }}>
                  <LinkView
                    to={`/faq/${faq.id}`}
                    css={{ display: 'flex', alignItems: 'center' }}
                  >
                    <div css={{ flex: 1 }}>
                      <Text
                        bold
                        color="primary"
                        size="lg"
                        css={{ lineHeight: 1.4, textDecoration: 'underline' }}
                      >
                        {faq.question}
                      </Text>
                      <div
                        css={{
                          paddingTop: 8,
                          paddingBottom: 16,
                          paddingRight: 16,
                        }}
                      >
                        <Text
                          css={{
                            overflow: 'hidden',
                            display: '-webkit-box',
                            '-webkit-box-orient': 'vertical',
                            '-webkit-line-clamp': '2',
                          }}
                          lineHeight={1.4}
                        >
                          {faq.answer}
                        </Text>
                      </div>
                    </div>
                    <MdChevronRight
                      css={(theme: Theme) => ({ color: theme.color.black60 })}
                      size={24}
                    />
                  </LinkView>
                  <Divider />
                </div>
              ))}
            </Section>
          </React.Fragment>
        ))}
      </div>
    </Layout>
  );
};
