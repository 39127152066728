import {
  createSlice,
  createEntityAdapter,
  PayloadAction,
  EntityState,
} from '@reduxjs/toolkit';
import { News } from 'core/models';
import { ProcessStatus } from 'states';

export const entityAdapter = createEntityAdapter<News>({
  selectId: (model) => model.id,
  sortComparer: (a, b) =>
    b.publicationPeriodFrom.localeCompare(a.publicationPeriodFrom), // Descending
});

interface NewsState extends EntityState<News> {
  status: ProcessStatus;
}

const initialState: NewsState = entityAdapter.getInitialState({
  status: 'idle',
});

const slice = createSlice({
  name: 'news',
  initialState,
  reducers: {
    resetStatus(state) {
      state.status = 'idle';
    },
    list(state) {
      state.status = 'loading';
    },
    listSuccess(state, action: PayloadAction<News[]>) {
      entityAdapter.addMany(state, action.payload);
      state.status = 'success';
    },
    listFailure(state) {
      state.status = 'failure';
    },
  },
});

export const actions = slice.actions;
export default slice;
