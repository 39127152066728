import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { AuthService, ScreenService } from 'core/services';
import { useAppDispatch, useAppSelector } from 'states';
import * as FAQState from 'states/faq';
import { FAQPresenter } from './presenter';

export const FAQ: React.VFC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const state = useAppSelector((s) => s);
  const faqCategories = FAQState.selectors.selectAll(state.faq);

  React.useEffect(() => {
    dispatch(FAQState.actions.list());
  }, [dispatch]);

  return (
    <>
      <Helmet>
        <title>{ScreenService.getDocumentTitle(t('ui.pages.FAQ.title'))}</title>
      </Helmet>
      <FAQPresenter
        faqs={faqCategories}
        showNavigator={!!AuthService.getSession()}
      />
    </>
  );
};
