import axios from 'axios';
import { ApiClient } from '../apiClient';
import { ApiError } from '../errors';
import { Consent } from '../../models';

export class ConsentApi extends ApiClient {
  async get(): Promise<Consent> {
    try {
      const response = await this._client.get<Consent>('/user/consent');
      return response.data;
    } catch (e) {
      if (axios.isAxiosError(e)) {
        throw new ApiError(e.response?.status);
      }
      throw e;
    }
  }

  async post(consent: Omit<Consent, 'userId'>): Promise<void> {
    try {
      await this._client.post('/user/consent', consent);
    } catch (e) {
      if (axios.isAxiosError(e)) {
        throw new ApiError(e.response?.status);
      }
      throw e;
    }
  }
}
