import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProcessStatus } from 'states';
import * as authState from '../auth';

interface AppState {
  status: ProcessStatus;
  presentation: 'public' | 'private' | 'signed_out';
  redirectTo?: string;
}

const initialState: AppState = {
  status: 'idle',
  presentation: 'public',
};

const slice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    resetStatus(state) {
      state.status = 'idle';
    },
    boot(state, _: PayloadAction<{ cryptogram?: string; pathname: string }>) {
      state.status = 'loading';
    },
    bootSuccess(
      state,
      action: PayloadAction<{
        authenticated: boolean;
        redirectTo?: string;
      }>,
    ) {
      const { authenticated, redirectTo } = action.payload;
      state.status = 'success';
      state.presentation = authenticated ? 'private' : 'public';
      state.redirectTo = redirectTo;
    },
    bootFailure(state) {
      state.status = 'failure';
    },
    redirect(state, action: PayloadAction<{ url: string }>) {
      state.redirectTo = action.payload.url;
    },
    redirectSuccess(state) {
      state.redirectTo = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(authState.actions.disconnectSession, (state) => {
      state.status = 'idle';
      state.presentation = 'public';
    });
    builder.addCase(authState.actions.signInSuccess, (state) => {
      state.presentation = 'private';
    });
    builder.addCase(authState.actions.signUpSuccess, (state) => {
      state.presentation = 'private';
    });
    builder.addCase(authState.actions.signOutSuccess, (state) => {
      state.presentation = 'signed_out';
    });
  },
});

export const actions = slice.actions;
export const reducer = slice.reducer;
