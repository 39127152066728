const theme = {
  breakpoint: {
    lg: 992,
  },
  color: {
    primary: '#00B0C5', // Figma: blue100BrandColor
    success: '#41C036', // Figma: feedback / success
    alert: '#FD557E', // Figma: feedback / alert
    error: '#EE0004', //Figma: undefined
    divider: '#EAEAEA', // Figma: grayBorder
    buttonDisabled: '#EAEAEA', // Figma: grayBorder
    buttonTextDisabled: '#A7A7A7', // Figma: undefined
    textPrimary: '#222222', // Figma: black80Text
    textSecondary: '#666666', // Figma: black60
    iconInactive: '#666666', // Figma: undefined
    inputBorder: '#949494', // Figma: undefined
    black60: '#666666', // Figma: Black 60
    white: '#FFFFFF', // Figma: white
  },
  fontSize: {
    xl: 18,
    lg: 16,
    md: 14,
    sm: 12,
    xs: 10,
  },
  iconSize: {
    xl: 48,
    lg: 32,
    md: 24,
    sm: 16,
  },
  text: {
    header1: {
      fontSize: '16px',
      fontWeight: 'bold',
    },
    header2: {
      fontSize: '14px',
      fontWeight: 'bold',
    },
  },
};

export type Color = keyof typeof theme.color;
export type FontSize = keyof typeof theme.fontSize;
export type IconSize = keyof typeof theme.iconSize;
export type Theme = typeof theme;
export type BreakSize = keyof typeof theme.breakpoint;

export const mediaQueries = Object.entries(theme.breakpoint).reduce(
  (result, [size, bp]) => {
    result[size as BreakSize] = `@media (min-width: ${bp}px)`;
    return result;
  },
  {} as Record<BreakSize, string>,
);

export default theme;
