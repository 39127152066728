import React from 'react';
import { mediaQueries } from 'ui/theme';

export const AboutImage: React.VFC = () => {
  return (
    <div
      css={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
      }}
    >
      <img
        alt=""
        css={{
          objectFit: 'cover',
          width: '100%',
          [mediaQueries.lg]: {
            width: 480,
          },
        }}
        src="images/service-overview1.png"
      />
    </div>
  );
};
