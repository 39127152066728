import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProcessStatus } from 'states';
import { Consent } from 'core/models';

export interface ConsentState {
  status: ProcessStatus;
  updateStatus: ProcessStatus;
  consent?: Consent;
}

const initialState: ConsentState = {
  status: 'idle',
  updateStatus: 'idle',
};

const slice = createSlice({
  name: 'consent',
  initialState,
  reducers: {
    resetStatus(state) {
      state.status = 'idle';
    },
    resetUpdateStatus(state) {
      state.updateStatus = 'idle';
    },
    get(state) {
      state.status = 'loading';
    },
    getSuccess(state, action: PayloadAction<{ consent: Consent }>) {
      state.status = 'success';
      state.consent = action.payload.consent;
    },
    getFailure(state) {
      state.status = 'failure';
    },
    update(state, _: PayloadAction<{ consent: Consent }>) {
      state.updateStatus = 'loading';
    },
    updateSuccess(state, action: PayloadAction<{ consent: Consent }>) {
      state.updateStatus = 'success';
      state.consent = action.payload.consent;
    },
    updateFailure(state) {
      state.updateStatus = 'failure';
    },
  },
});

export const actions = slice.actions;
export const reducer = slice.reducer;
