import React from 'react';
import { useAppDispatch, useAppSelector } from 'states';
import * as appStates from 'states/app';

export const useRedirect = () => {
  const dispatch = useAppDispatch();
  const redirectTo = useAppSelector(appStates.selectors.selectRedirectTo);

  React.useEffect(() => {
    if (redirectTo) {
      dispatch(appStates.actions.redirectSuccess());
    }
  }, [dispatch, redirectTo]);

  return { redirectTo };
};
