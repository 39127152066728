import React from 'react';
import { MdOpenInNew } from 'react-icons/md';
import { LinkText } from 'ui/components/shared';
import { Theme } from 'ui/theme';

export interface ExternalLinkProps {
  url?: string;
}

export const ExternalLink: React.FC<ExternalLinkProps> = ({
  children,
  url,
}) => {
  return (
    <div css={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      <LinkText href={url} size="sm" target="_blank">
        {children}
      </LinkText>
      <MdOpenInNew
        css={(theme: Theme) => ({
          marginLeft: 4,
          color: theme.color.primary,
          size: theme.iconSize.sm,
        })}
      />
    </div>
  );
};
