import React from 'react';

interface SpacerProps {
  horizontal?: boolean;
  space: 4 | 8 | 12 | 16 | 24 | 32 | 48;
}

export const Spacer: React.FC<SpacerProps> = ({ horizontal, space }) => {
  return (
    <div
      css={{
        marginTop: !horizontal ? space : 'unset',
        marginRight: horizontal ? space : 'unset',
      }}
    />
  );
};
