import React from 'react';
import { Divider, Text } from 'ui/components/shared';
import { touchableOpacityStyle } from 'ui/styles';

export interface ListItemProps {
  title: string | React.ReactNode;
  onClick?: () => void;
}

export const ListItem: React.VFC<ListItemProps> = ({ title, onClick }) => {
  return (
    <div
      css={[{ marginBottom: 16 }, onClick ? touchableOpacityStyle : undefined]}
      onClick={onClick}
    >
      <div css={{ paddingTop: 8, paddingBottom: 8 }}>
        {typeof title === 'string' ? <Text size="sm">{title}</Text> : title}
      </div>
      <Divider />
    </div>
  );
};
