import { Action } from '@reduxjs/toolkit';
import { concat, from, Observable, of, throwError } from 'rxjs';
import { filter, mergeMap, catchError } from 'rxjs/operators';
import { getApiClient } from 'core/api';
import { DataPartnerApi } from 'core/api/list';
import { AuthService } from 'core/services';
import { actions } from '../slice';

export const listEpic = (action$: Observable<Action>) =>
  action$.pipe(
    filter(actions.list.match),
    mergeMap(() =>
      from(
        new DataPartnerApi(
          getApiClient({ idToken: AuthService.currentIdToken() }),
        ).list(),
      ).pipe(
        mergeMap((result) =>
          concat(of(actions.listSuccess(result)), of(actions.resetStatus())),
        ),
        catchError((error) =>
          concat(
            of(actions.listFailure()),
            of(actions.resetStatus()),
            throwError(error),
          ),
        ),
      ),
    ),
  );
