import { Factory } from 'miragejs';
import { Consent } from 'core/models';

export const factories = {
  consent: Factory.extend<Consent>({
    userId: (i) => `User ${i}`,
    permissions: {
      dataPartners: {},
    },
  }),

  dataPartner: Factory.extend({
    name: (i) => `Data Partner ${i}`,
    displayName: (i) => `Data Partner Display Name ${i}`,
    iconUri: () => `https://example.com/data-partner-icon{i}.png`,
  }),

  advertiser: Factory.extend({
    id: (i) => `${i}`,
    name: (i) => `Advertiser ${i}`,
    url: (i) => `https://advertiser${i}.com`,
  }),

  faqCategory: Factory.extend({
    id: (i) => `${i}`,
    category: (i) => `FAQ Category ${i}`,
    faqs: (i) => [
      {
        id: `${i}`,
        question: `Question ${i}`,
        answer: `Answer ${i}`,
      },
    ],
  }),

  publisherMedia: Factory.extend({
    id: (i) => `${i}`,
    name: (i) => `PublisherMedia ${i}`,
    url: (i) => `https://publisher-media${i}.com`,
  }),

  news: Factory.extend({
    id: (i) => `${i}`,
    title: (i) => `News ${i}`,
    body: (i) => `This is news ${i}.`,
    publicationPeriodFrom: new Date().toISOString(),
    publicationPeriodTo: new Date().toISOString(),
  }),

  user: Factory.extend({
    id: (i) => `user${i}`,
  }),
};
