import React from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { AuthService, ScreenService } from 'core/services';
import { ServerErrorPresenter } from './presenter';

export const ServerError: React.VFC = () => {
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <title>
          {ScreenService.getDocumentTitle(
            t('ui.pages.Error.ServerError.title'),
          )}
        </title>
      </Helmet>
      <ServerErrorPresenter showNavigator={!!AuthService.getSession()} />
    </>
  );
};
