import React from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { QUERY_PARAM_CRYPTOGRAM } from 'core/const';
import { useAppDispatch } from 'states';
import * as appStates from 'states/app';

export const useBoot = () => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const location = useLocation();

  React.useEffect(() => {
    const cryptogram = searchParams.get(QUERY_PARAM_CRYPTOGRAM) ?? undefined;
    dispatch(
      appStates.actions.boot({
        cryptogram,
        pathname: location.pathname,
      }),
    );
  }, [dispatch, location, searchParams]);
};
