import styled from '@emotion/styled';
import { touchableOpacityStyle } from 'ui/styles';
import { Color, FontSize } from 'ui/theme';

export interface LinkTextProps {
  color?: Color;
  size?: FontSize | number;
  bold?: boolean;
}

export const LinkText = styled.a<LinkTextProps>(
  ({ color, size, bold, theme }) => [
    touchableOpacityStyle,
    {
      color: theme.color[color ?? 'primary'],
      fontSize: typeof size === 'number' ? size : theme.fontSize[size ?? 'md'],
      fontWeight: bold ? 'bold' : undefined,
      textDecorationColor: theme.color[color ?? 'primary'],
      textDecorationLine: 'underline',
      textDecorationStyle: 'solid',
    },
  ],
);
