import React from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { ConsentService, ScreenService } from 'core/services';
import { useAppDispatch, useAppSelector } from 'states';
import * as AdvertiserState from 'states/advertiser';
import * as ConsentState from 'states/consent';
import * as DataPartnerState from 'states/dataPartner';
import * as NewsState from 'states/news';
import * as PublisherMediaState from 'states/publisherMedia';
import { HomePresenter } from './presenter';

export const Home: React.VFC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const state = useAppSelector((s) => s);
  const consent = ConsentState.selectors.selectConsent(state);
  const dataPartners = DataPartnerState.selectors.selectAll(state.dataPartner);
  const consented = React.useMemo(
    () => (consent ? ConsentService.isConsentedAny(consent) : false),
    [consent],
  );
  const advertisers = AdvertiserState.selectors.selectAll(state.advertiser);
  const news = NewsState.selectors.selectAvailableNews(state);
  const publisherMedias = PublisherMediaState.selectors.selectAll(
    state.publisherMedia,
  );

  React.useEffect(() => {
    dispatch(AdvertiserState.actions.list());
    dispatch(ConsentState.actions.get());
    dispatch(DataPartnerState.actions.list());
    dispatch(NewsState.actions.list());
    dispatch(PublisherMediaState.actions.list());
  }, [dispatch]);

  return (
    <>
      <Helmet>
        <title>{ScreenService.getDocumentTitle(t('global.home'))}</title>
      </Helmet>
      <HomePresenter
        advertisers={advertisers}
        consented={consented}
        dataPartners={dataPartners}
        newsList={news}
        publisherMedias={publisherMedias}
      />
    </>
  );
};
