import React from 'react';
import { useTranslation } from 'react-i18next';
import { MdOpenInNew } from 'react-icons/md';
import config from 'core/config';
import {
  AboutImage,
  Button,
  Checkbox,
  Layout,
  Link,
  LinkText,
  Spacer,
  Text,
  TopHeader,
} from 'ui/components/shared';
import { mediaQueries, Theme } from 'ui/theme';
import { ExternalLinkItem, SignupExternalLinkItem } from '../Settings/ListItem';

const AboutButton = () => {
  const { t } = useTranslation();
  return (
    <LinkText
      css={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textDecoration: 'none',
        textDecorationLine: 'none',
      }}
      href={config.aboutPageUrl}
      target="_blank"
    >
      <Text bold color="primary" size="md">
        {t('ui.pages.Start.about')}
      </Text>
      <MdOpenInNew
        css={(theme: Theme) => ({
          marginLeft: 8,
          color: theme.color.primary,
          fontSize: theme.iconSize.md,
        })}
      />
    </LinkText>
  );
};

export interface SignUpPresenterProps {
  onSignUp?: () => void;
}

export const SignUpPresenter: React.VFC<SignUpPresenterProps> = ({
  onSignUp,
}) => {
  const { t } = useTranslation();
  const [termsOfServiceChecked, setTermsOfServiceChecked] =
    React.useState(false);
  const [privacyPolicyChecked, setPrivacyPolicyChecked] = React.useState(false);

  const signUp = React.useCallback(() => onSignUp?.(), [onSignUp]);

  return (
    <Layout>
      <TopHeader>
        <img
          css={{
            objectFit: 'contain',
            width: '80%',
            [mediaQueries.lg]: { width: '40%' },
          }}
          alt="Themix Personal"
          src="/images/logo.svg"
        />
      </TopHeader>
      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: 16,
        }}
      >
        <AboutImage />
        <Spacer space={16} />
        <AboutButton />
      </div>
      <div css={{ padding: 16 }}>
        <div css={{ display: 'flex', alignItems: 'center' }}>
          <Checkbox
            data-testid="termsOfUse"
            checked={termsOfServiceChecked}
            onChange={(event) => setTermsOfServiceChecked(event.target.checked)}
          />
          <SignupExternalLinkItem
            link={config.termsOfServiceUrl}
            title={t('global.termsOfUse')}
          />
          <Text>{t('ui.pages.SignUp.agree')}</Text>
        </div>
        <Spacer space={8} />
        <div css={{ display: 'flex', alignItems: 'center' }}>
          <Checkbox
            data-testid="privaryPolicy"
            checked={privacyPolicyChecked}
            onChange={(event) => setPrivacyPolicyChecked(event.target.checked)}
          />
          <SignupExternalLinkItem
            link={config.privacyPolicyUrl}
            title={t('global.privacyPolicy')}
          />
          <Text>{t('ui.pages.SignUp.agree')}</Text>
        </div>
        <Spacer space={16} />
        <Button
          disabled={!termsOfServiceChecked || !privacyPolicyChecked}
          onClick={signUp}
        >
          {t(`ui.pages.SignUp.submit`)}
        </Button>
        <Spacer space={16} />
        <Text color="textSecondary" size="sm" lineHeight={1.5}>
          {t('ui.pages.SignUp.annotation1')}
        </Text>
        <Spacer space={4} />
        <Text color="textSecondary" size="sm" lineHeight={1.5}>
          {t('ui.pages.SignUp.annotation2')}
        </Text>
        <Spacer space={4} />
        <Text color="textSecondary" size="sm" lineHeight={1.5}>
          {t('ui.pages.SignUp.annotation3')}
        </Text>
      </div>
    </Layout>
  );
};
