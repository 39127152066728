import { createSelector } from '@reduxjs/toolkit';
import { News } from 'core/models';
import { RootState } from 'states/store';

export const select = (state: RootState) => state.news;

export const selectStatus = createSelector(select, (state) => state.status);

export const selectAvailableNews = createSelector(select, (state) =>
  (
    Object.values(state.entities).filter(
      (n) =>
        n &&
        new Date(n.publicationPeriodFrom) <= new Date() &&
        new Date(n.publicationPeriodTo) >= new Date(),
    ) as News[]
  ).sort((a, b) =>
    b.publicationPeriodFrom.localeCompare(a.publicationPeriodTo),
  ),
);
