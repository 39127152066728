import styled from '@emotion/styled';
import { touchableOpacityStyle } from 'ui/styles';

export const ToastLinkText = styled.a(({ theme }) => [
  touchableOpacityStyle,
  {
    color: theme.color.primary,
    fontSize: 16,
    fontWeight: 'bold',
    textDecorationColor: theme.color.primary,
    textDecorationLine: 'underline',
    textDecorationStyle: 'solid',
  },
]);
