import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SSORequest } from 'core/api/auth';
import { ProcessStatus } from 'states';
import * as appState from 'states/app';

export interface AuthState {
  status: ProcessStatus;
  authenticated: boolean;
  error?: Error;
}

const initialState: AuthState = {
  status: 'idle',
  authenticated: false,
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    resetStatus(state) {
      state.status = 'idle';
      state.error = undefined;
    },
    disconnectSession(state) {
      state.status = 'idle';
      state.authenticated = false;
    },
    signIn(state, _: PayloadAction<SSORequest>) {
      state.status = 'loading';
    },
    signInSuccess(state) {
      state.status = 'success';
      state.authenticated = true;
    },
    signInFailure(state, action: PayloadAction<{ error: Error } | undefined>) {
      state.status = 'failure';
      state.error = action.payload?.error;
    },
    signUp(state, _: PayloadAction<SSORequest>) {
      state.status = 'loading';
    },
    signUpSuccess(state) {
      state.status = 'success';
      state.authenticated = true;
    },
    signUpFailure(state, action: PayloadAction<{ error: Error } | undefined>) {
      state.status = 'failure';
      state.error = action.payload?.error;
    },
    signOut(state) {
      state.status = 'loading';
    },
    signOutSuccess(state) {
      state.status = 'success';
      state.authenticated = false;
    },
    signOutFailure(state, action: PayloadAction<{ error: Error } | undefined>) {
      state.status = 'failure';
      state.error = action.payload?.error;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(appState.actions.bootSuccess, (state, action) => {
      state.authenticated = action.payload.authenticated;
    });
  },
});

export const actions = slice.actions;
export const reducer = slice.reducer;
