import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'states/store';

const selectApp = (state: RootState) => state.app;

const selectStatus = createSelector(selectApp, (state) => state.status);

const selectRedirectTo = createSelector(selectApp, (state) => state.redirectTo);

const selectPresentation = createSelector(
  selectApp,
  (state) => state.presentation,
);

export const selectors = {
  selectPresentation,
  selectStatus,
  selectRedirectTo,
};
