import axios from 'axios';
import config from 'core/config';
import { News } from 'core/models';
import { ApiError } from '../errors';

export class NewsApi {
  async list(): Promise<News[]> {
    try {
      const response = await axios.get<News[]>(config.newsJsonFileUrl);
      return response.data;
    } catch (e) {
      if (axios.isAxiosError(e)) {
        throw new ApiError(e.response?.status);
      }
      throw e;
    }
  }
}
