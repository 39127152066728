import { Action } from '@reduxjs/toolkit';
import { concat, from, Observable, of, throwError } from 'rxjs';
import { catchError, filter, mergeMap } from 'rxjs/operators';
import { getApiClient } from 'core/api';
import { ConsentApi } from 'core/api/consent';
import { AuthService } from 'core/services';
import { actions } from '../slice';

export const getEpic = (action$: Observable<Action>) =>
  action$.pipe(
    filter(actions.get.match),
    mergeMap(() =>
      from(
        new ConsentApi(
          getApiClient({ idToken: AuthService.currentIdToken() }),
        ).get(),
      ).pipe(
        mergeMap((consent) =>
          concat(
            of(actions.getSuccess({ consent })),
            of(actions.resetStatus()),
          ),
        ),
        catchError((error) =>
          concat(
            of(actions.getFailure()),
            of(actions.resetStatus()),
            throwError(error),
          ),
        ),
      ),
    ),
  );
