import React from 'react';
import { useTranslation } from 'react-i18next';
import config from 'core/config';
import {
  ConfirmDialog,
  PageHeader,
  Layout,
  Spacer,
  Divider,
} from 'ui/components/shared';
import { List } from './List';
import { ActionItem, ExternalLinkItem, LabelItem, LinkItem } from './ListItem';

export interface SettingsPresenterProps {
  onClickSignOut?: () => void;
}

export const SettingsPresenter: React.VFC<SettingsPresenterProps> = ({
  onClickSignOut,
}) => {
  const { t } = useTranslation();
  const [signOutDialogVisible, setSignOutDialogVisible] = React.useState(false);

  return (
    <>
      <Layout
        activeNavigationItem="settings"
        header={<PageHeader title={t('global.menu')} />}
        showNavigator
      >
        <div css={{ padding: 16 }}>
          <List>
            <ActionItem
              chevron
              onClick={() => setSignOutDialogVisible(true)}
              title={t('global.logout')}
            />
            <Divider />
            <LinkItem
              link="/account/delete"
              title={t('ui.pages.Settings.withdrawal')}
            />
          </List>
          <Spacer space={16} />
          <List>
            <ExternalLinkItem link={config.faqUrl} title={t('global.faq')} />
            <Divider />
            <ExternalLinkItem
              link={config.contactsUrl}
              title={t('global.contact')}
            />
            <Divider />
            <ExternalLinkItem
              link={config.termsOfServiceUrl}
              title={t('global.termsOfUse')}
            />
            <Divider />
            <ExternalLinkItem
              link={config.privacyPolicyUrl}
              title={t('global.privacyPolicy')}
            />
          </List>
        </div>
      </Layout>
      <ConfirmDialog
        visible={signOutDialogVisible}
        title={t('ui.pages.Settings.logoutConfirmDialogTitle')}
        onCancel={() => setSignOutDialogVisible(false)}
        onOK={() => onClickSignOut?.()}
        cancelTitle={t('global.cancel')}
        okTitle={t(`global.logout`)}
      />
    </>
  );
};
