import React from 'react';
import { useTranslation } from 'react-i18next';
import { MdChevronRight } from 'react-icons/md';
import {
  Divider,
  Layout,
  PageHeader,
  Section,
  Text,
} from 'ui/components/shared';
import { touchableOpacityStyle } from 'ui/styles';
import { Theme } from 'ui/theme';

const Item: React.VFC<{ title: string; onClick?: () => void }> = ({
  title,
  onClick,
}) => (
  <div
    css={[
      touchableOpacityStyle,
      { '&:not(:last-child)': { marginBottom: 16 } },
    ]}
    onClick={onClick}
  >
    <div
      css={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: 8,
      }}
    >
      <Text size="sm">{title}</Text>
      <MdChevronRight
        css={(theme: Theme) => ({
          color: theme.color.textSecondary,
          fontSize: theme.iconSize.md,
        })}
      />
    </div>
    <Divider />
  </div>
);

export const LicensesPresenter: React.VFC = () => {
  const { t } = useTranslation();

  return (
    <Layout
      activeNavigationItem="settings"
      header={<PageHeader title={t('global.license')} />}
      showNavigator
    >
      <div css={{ padding: 16 }}>
        <Section>
          <Item title="Data icons created by pongsakornRed - Flaticon" />
          <Item title="Data icons created by Freepik - Flaticon" />
          <Item title="Profit icons created by Freepik - Flaticon" />
          <Item title="Preview icons created by Freepik - Flaticon" />
        </Section>
      </div>
    </Layout>
  );
};
