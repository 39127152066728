import Axios from 'axios';
import { PublisherMedia } from 'core/models';
import { ApiClient } from '../apiClient';
import { ApiError } from '../errors';

export class PublisherMediaApi extends ApiClient {
  async list(): Promise<PublisherMedia[]> {
    try {
      const response = await this._client.get<{
        publisherMedias: PublisherMedia[];
      }>('/publishermedias');
      return response.data.publisherMedias;
    } catch (e) {
      if (Axios.isAxiosError(e)) {
        throw new ApiError(e.response?.status);
      }
      throw e;
    }
  }
}
