import React from 'react';
import { useTranslation } from 'react-i18next';
import { MdHomeFilled, MdMenu } from 'react-icons/md';
import { LinkView } from 'ui/components/shared';
import { Theme } from 'ui/theme';
import { NavigationItem } from '..';

const Icon: React.VFC<{ active?: NavigationItem; item: NavigationItem }> = ({
  active,
  item,
}) => {
  const NavIcon = item === 'settings' ? MdMenu : MdHomeFilled;
  return (
    <NavIcon
      css={(theme: Theme) => [
        {
          color: theme.color[active === item ? 'primary' : 'iconInactive'],
          fontSize: theme.iconSize.md,
        },
      ]}
    />
  );
};

const NavItem: React.VFC<{
  active: boolean;
  icon: React.ReactElement;
  title: string;
  path?: string;
}> = ({ active, icon, title, path }) => {
  return (
    <LinkView
      css={{
        display: 'flex',
        alignItems: 'center',
        padding: '16px 0',
      }}
      to={path ?? ''}
    >
      {icon}
      <div
        css={(theme: Theme) => ({
          marginLeft: 8,
          color: theme.color[active ? 'primary' : 'iconInactive'],
          fontSize: 16,
          fontWeight: 'bold',
        })}
      >
        {title}
      </div>
    </LinkView>
  );
};

export interface SideNavigationProps {
  active?: NavigationItem;
}

export const SideNavigation: React.VFC<SideNavigationProps> = ({ active }) => {
  const { t } = useTranslation();
  return (
    <div css={{ paddingTop: 16 }}>
      <NavItem
        active={active === 'home'}
        icon={<Icon active={active} item="home" />}
        title={t('global.home')}
        path="/"
      />
      <NavItem
        active={active === 'settings'}
        icon={<Icon active={active} item="settings" />}
        title={t('global.menu')}
        path="/settings"
      />
    </div>
  );
};
