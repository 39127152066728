import { Model } from 'miragejs';
import {
  Advertiser,
  Consent,
  DataPartner,
  FAQCategory,
  News,
  PublisherMedia,
  User,
} from 'core/models';

export const models = {
  consent: Model.extend<Partial<Consent>>({}),
  dataPartner: Model.extend<Partial<DataPartner>>({}),
  advertiser: Model.extend<Partial<Advertiser>>({}),
  faqCategory: Model.extend<Partial<FAQCategory>>({}),
  news: Model.extend<Partial<News>>({}),
  publisherMedia: Model.extend<Partial<PublisherMedia>>({}),
  user: Model.extend<Partial<User>>({}),
};
