import styled from '@emotion/styled';
import { Text } from '../Text';
import { Spacer } from '../Spacer';
import { Divider } from '../Divider';
import React from 'react';

const dialogButtonColor = '#007AFF';
const dialogBackgroundColor = '#F2F2F2';

const Button = styled.div<{ bold?: boolean }>(({ bold, theme }) => ({
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: 16,
  color: dialogButtonColor,
  fontSize: theme.fontSize.md,
  fontWeight: bold ? 'bold' : 'normal',
  ':active': { opacity: 0.4 },
  '&:not(:last-child)': {
    borderRight: `0.5px solid ${theme.color.divider}`,
  },
}));

export interface ConfirmDialogPresenterProps {
  cancelId?: string;
  cancelTitle?: string;
  message?: string;
  okId?: string;
  okTitle?: string;
  onCancel?: () => void;
  onOK?: () => void;
  title?: string;
}

export const ConfirmDialogPresenter: React.VFC<ConfirmDialogPresenterProps> = ({
  cancelId,
  cancelTitle,
  message,
  okId,
  okTitle,
  onCancel,
  onOK,
  title,
}) => (
  <div
    css={{
      margin: '0 auto',
      borderRadius: 16,
      backgroundColor: dialogBackgroundColor,
      minWidth: 240,
      maxWidth: '80%',
      maxHeight: '80%',
    }}
  >
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '20px 16px',
      }}
    >
      {title && (
        <Text size="lg" lineHeight={1.4} css={{ textAlign: 'center' }} bold>
          {title}
        </Text>
      )}
      {message && (
        <>
          <Spacer space={8} />
          <Text size="sm" lineHeight={1.4} css={{ textAlign: 'center' }}>
            {message}
          </Text>
        </>
      )}
    </div>
    <Divider />
    <div
      css={{
        display: 'flex',
        "&:hover": {
            cursor: 'pointer'
        }
      }}
    >
      <Button
        data-testid={cancelId ?? 'ConfirmDialog.cancel'}
        onClick={onCancel}
      >
        {cancelTitle}
      </Button>
      <Button data-testid={okId ?? 'ConfirmDialog.ok'} onClick={onOK} bold>
        {okTitle}
      </Button>
    </div>
  </div>
);
