import axios from 'axios';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { startMirage } from 'mirage';
import fetchAdapter from '@vespaiach/axios-fetch-adapter';

const environment = process.env.NODE_ENV;

if (environment !== 'production') {
  // Set fetchAdapter instead default adapter which using xhr.
  // The default adapter of axios doesn't work with mirage's passthrough route.
  // https://github.com/miragejs/miragejs/issues/1006
  // https://stackoverflow.com/questions/69198465/miragejs-cannot-use-axios-if-i-call-api-with-passthrough
  // https://chmono.com/manifest-v3-service-worker-axios/
  axios.defaults.adapter = fetchAdapter;
  startMirage({ environment });
}

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
