import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { css } from '@emotion/react';
import config from 'core/config';
import {
  Layout,
  LinkText,
  PageHeader,
  Section,
  Text,
} from 'ui/components/shared';

const styles = {
  container: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 16,
  }),
};

export const ErrorPresenter: React.VFC<{
  title: string;
  message: string;
  showNavigator?: boolean;
}> = ({ title, message, showNavigator = false }) => {
  const { t } = useTranslation();

  return (
    <Layout header={<PageHeader title={title} />} showNavigator={showNavigator}>
      <div css={{ padding: 16 }}>
        <Section>
          <Text lineHeight={1.4}>{message}</Text>
          <div css={styles.container}>
            <LinkText
              color="textPrimary"
              href={config.contactsUrl}
              target="_blank"
              bold
            >
              {t('ui.pages.Error.contact')}
            </LinkText>
          </div>
        </Section>
        <div css={styles.container}>
          <Link to="/">
            <LinkText color="textPrimary" bold>
              {t('global.backToTop')}
            </LinkText>
          </Link>
        </div>
      </div>
    </Layout>
  );
};
