import { ToastContainer, cssTransition } from 'react-toastify';
import React from 'react';

export const CloseButton = ({ closeToast }: { closeToast: () => void }) => {
  return (
    <div onClick={closeToast} style={{ display: 'flex', alignItems: 'center' }}>
      <span>✖️</span>
    </div>
  );
};

export const Toast: React.VFC = () => (
  <ToastContainer
    autoClose={5000}
    draggableDirection="y"
    draggablePercent={50}
    hideProgressBar={true}
    position="top-right"
    toastStyle={{
      // The backgroundColor is from Material Design's Snackbar
      backgroundColor: '#323232',
      color: '#FFFFFF',
    }}
    // https://fkhadra.github.io/react-toastify/custom-animation/
    transition={cssTransition({
      enter: 'animate__animated animate__slideInDown',
      exit: 'animate__animated animate__slideOutUp',
    })}
    closeButton={CloseButton}
  />
);
