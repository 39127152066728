import React from 'react';
import { Theme } from 'ui/theme';
import { HelpIcon } from '..';

export interface SectionTitleProps {
  icon?: React.ReactElement;
  title: string;
  help?: string | React.ReactElement;
  helpTooltipId?: string;
}

export const SectionTitle: React.VFC<SectionTitleProps> = ({
  icon,
  help,
  helpTooltipId,
  title,
}) => {
  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      {icon && <div css={{ marginRight: 8 }}>{icon}</div>}
      <div
        css={(theme: Theme) => ({
          color: theme.color.textPrimary,
          fontSize: 16,
          fontWeight: 'bold',
        })}
      >
        {title}
      </div>
      {help && (
        <div css={{ marginLeft: 8 }}>
          <HelpIcon testId={helpTooltipId} help={help} />
        </div>
      )}
    </div>
  );
};
