import React from 'react';
import { useTranslation } from 'react-i18next';
import { News } from 'core/models';
import { Layout, PageHeader, Section, Text } from 'ui/components/shared';
import dayjs from 'dayjs';

export const NewsDetailPresenter: React.VFC<{ news?: News }> = ({ news }) => {
  const { t } = useTranslation();
  return (
    <Layout
      activeNavigationItem="home"
      header={<PageHeader title={t('ui.pages.NewsDetail.title')} />}
      showNavigator
    >
      <div css={{ padding: 16 }}>
        <Section>
          <Text bold css={{ marginBottom: 8 }} lineHeight={1.4}>
            {news?.title}
          </Text>
          {news && (
            <Text size="sm" color="textSecondary" css={{ marginBottom: 32 }}>
              {dayjs(news.publicationPeriodFrom).format('YYYY/MM/DD HH:mm')}
            </Text>
          )}
          <Text lineHeight={1.4}>{news?.body}</Text>
        </Section>
      </div>
    </Layout>
  );
};
