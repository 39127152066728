import React from 'react';
import { Theme } from 'ui/theme';

export interface PageHeaderProps {
  title: string;
  left?: React.ReactNode;
  right?: React.ReactNode;
}

export const PageHeader: React.VFC<PageHeaderProps> = ({
  title,
  left,
  right,
}) => {
  return (
    <header
      css={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        minHeight: 44,
        padding: 16,
      }}
    >
      <div css={{ flex: 1 }}>{left}</div>
      <div css={{ display: 'flex', justifyContent: 'center' }}>
        <div
          css={(theme: Theme) => ({
            color: theme.color.textPrimary,
            fontSize: 14,
            fontWeight: 'bold',
          })}
        >
          {title}
        </div>
      </div>
      <div css={{ flex: 1 }}>{right}</div>
    </header>
  );
};
