import React from 'react';
import { css, Global } from '@emotion/react';
import emotionReset from 'emotion-reset';

const globalStyle = css({
  'html, body, #root': {
    height: '100%',
  },
  body: {
    fontFamily:
      '"Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro", "Noto sans CJK", "Meiryo", "メイリオ", "Yu Gothic", "游ゴシック", "MS Gothic", "ＭＳ ゴシック", sans-serif !important',
  },
});

export const AppStyle: React.VFC = () => (
  <>
    <Global styles={emotionReset} />
    <Global styles={globalStyle} />
  </>
);
