import Axios from 'axios';
import { Advertiser } from 'core/models';
import { ApiClient } from '../apiClient';
import { ApiError } from '../errors';

export class AdvertiserApi extends ApiClient {
  async list(): Promise<Advertiser[]> {
    try {
      const response = await this._client.get<{
        advertisers: Advertiser[];
      }>('/advertisers');
      return response.data.advertisers;
    } catch (e) {
      if (Axios.isAxiosError(e)) {
        throw new ApiError(e.response?.status);
      }
      throw e;
    }
  }
}
