import { Action } from '@reduxjs/toolkit';
import { concat, from, Observable, of, throwError } from 'rxjs';
import { catchError, filter, mergeMap } from 'rxjs/operators';
import { getApiClient } from 'core/api';
import { SignOutApi } from 'core/api/auth';
import { AuthService } from 'core/services';
import { actions } from '../slice';

export const signOut = async (): Promise<void> => {
  const idToken = AuthService.currentIdToken();
  const client = getApiClient({ idToken });
  await new SignOutApi(client).signOut();
  AuthService.removeSession();
};

export const signOutEpic = (action$: Observable<Action>) =>
  action$.pipe(
    filter(actions.signOut.match),
    mergeMap(() =>
      from(signOut()).pipe(
        mergeMap(() =>
          concat(of(actions.signOutSuccess()), of(actions.resetStatus())),
        ),
        catchError((error) =>
          concat(
            of(actions.signOutFailure()),
            of(actions.resetStatus()),
            throwError(error),
          ),
        ),
      ),
    ),
  );
