import React from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { useAppSelector } from 'states/store';
import * as appState from 'states/app';
import {
  ClientError,
  ConsentRequested,
  DataPartnerDetail,
  DeleteAccount,
  DeleteAccountComplete,
  DissentRequested,
  FAQ,
  FAQDetail,
  Home,
  Licenses,
  NewsDetail,
  NotFoundError,
  PrivacyPolicy,
  ServerError,
  Settings,
  SignUp,
  Start,
  TermsOfService,
} from 'ui/pages';

interface LocationState {
  from?: Location;
}

const Public: React.FC = ({ children }) => {
  const location = useLocation();
  const to = (location.state as LocationState)?.from?.pathname || '/';
  const state = useAppSelector((s) => s);
  const presentation = appState.selectors.selectPresentation(state);
  if (presentation === 'public' || presentation === 'signed_out') {
    return children as JSX.Element;
  } else {
    return <Navigate to={to} replace />;
  }
};

const Private: React.FC = ({ children }) => {
  const location = useLocation();
  const state = useAppSelector((s) => s);
  const presentation = appState.selectors.selectPresentation(state);
  if (presentation === 'private') {
    return children as JSX.Element;
  } else if (presentation === 'signed_out') {
    // Don't retain the location in state when signed out
    return <Navigate to="/start" replace />;
  } else {
    return <Navigate to="/start" state={{ from: location }} replace />;
  }
};

export const AppRoutes: React.FC = () => {
  return (
    <Routes>
      <Route
        path="/start"
        element={
          <Public>
            <Start />
          </Public>
        }
      />
      <Route
        path="/signup"
        element={
          <Public>
            <SignUp />
          </Public>
        }
      />
      <Route
        path="/"
        element={
          <Private>
            <Home />
          </Private>
        }
      />
      <Route
        path="/settings"
        element={
          <Private>
            <Settings />
          </Private>
        }
      />
      <Route
        path="/partners/:id"
        element={
          <Private>
            <DataPartnerDetail />
          </Private>
        }
      />
      <Route
        path="/consent-requested"
        element={
          <Private>
            <ConsentRequested />
          </Private>
        }
      />
      <Route
        path="/dissent-requested"
        element={
          <Private>
            <DissentRequested />
          </Private>
        }
      />
      <Route
        path="/account/delete"
        element={
          <Private>
            <DeleteAccount />
          </Private>
        }
      />
      <Route
        path="/account/delete/complete"
        element={<DeleteAccountComplete />}
      />
      <Route path="/licenses" element={<Licenses />} />
      <Route path="/terms-of-service" element={<TermsOfService />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/faq" element={<FAQ />} />
      <Route path="/faq/:id" element={<FAQDetail />} />
      <Route
        path="/news/:id"
        element={
          <Private>
            <NewsDetail />
          </Private>
        }
      />
      <Route path="/client-error" element={<ClientError />} />
      <Route path="/server-error" element={<ServerError />} />
      <Route path="*" element={<NotFoundError />} />
    </Routes>
  );
};
