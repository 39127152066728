import { Action } from '@reduxjs/toolkit';
import { concat, from, Observable, of, throwError } from 'rxjs';
import { filter, mergeMap, catchError } from 'rxjs/operators';
import { getApiClient } from 'core/api';
import { AccountApi } from 'core/api/account';
import { AuthService } from 'core/services';
import * as AuthState from 'states/auth';
import { actions } from '../slice';

const deleteAccount = async () => {
  await new AccountApi(
    getApiClient({ idToken: AuthService.currentIdToken() }),
  ).deleteAccount();
  AuthService.removeSession();
};

export const deleteAccountEpic = (action$: Observable<Action>) =>
  action$.pipe(
    filter(actions.deleteAccount.match),
    mergeMap(() =>
      from(deleteAccount()).pipe(
        mergeMap(() =>
          concat(
            of(actions.deleteAccountSuccess()),
            of(actions.resetStatus()),
            of(AuthState.actions.signOutSuccess()),
          ),
        ),
        catchError((error) =>
          concat(
            of(actions.deleteAccountFailure()),
            of(actions.resetStatus()),
            throwError(error),
          ),
        ),
      ),
    ),
  );
