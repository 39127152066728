import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';

const select = (state: RootState) => state.consent;

const selectStatus = createSelector(select, (state) => state.status);

const selectUpdateStatus = createSelector(
  select,
  (state) => state.updateStatus,
);

const selectConsent = createSelector(select, (state) => state.consent);

export const selectors = {
  selectStatus,
  selectUpdateStatus,
  selectConsent,
};
