import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { FAQ } from 'core/models';
import { AuthService, ScreenService } from 'core/services';
import { useAppDispatch, useAppSelector } from 'states';
import * as FAQState from 'states/faq';
import { FAQDetailPresenter } from './presenter';
import { NotFoundError } from '../Error';

export const FAQDetail: React.VFC = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [init, setInit] = React.useState(false);

  const dispatch = useAppDispatch();
  const state = useAppSelector((s) => s);
  const status = FAQState.selectors.selectStatus(state);
  const faqCategories = FAQState.selectors.selectAll(state.faq);

  const faq = React.useMemo(
    () =>
      faqCategories
        .reduce((acc, category) => acc.concat(category.faqs), [] as FAQ[])
        .find((item) => item.id === id),
    [faqCategories, id],
  );

  React.useEffect(() => {
    if (!init && faqCategories.length === 0) {
      dispatch(FAQState.actions.list());
    }
    setInit(true);
  }, [dispatch, init, faqCategories, setInit]);

  if (status !== 'loading' && !faq) {
    return <NotFoundError />;
  }

  return (
    <>
      <Helmet>
        <title>
          {ScreenService.getDocumentTitle(
            faq?.question || t('ui.pages.FAQDetail.title'),
          )}
        </title>
      </Helmet>
      <FAQDetailPresenter
        faq={faq}
        showNavigator={!!AuthService.getSession()}
      />
    </>
  );
};
