import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ScreenService } from 'core/services';
import { useAppDispatch, useAppSelector } from 'states';
import * as NewsState from 'states/news';
import { NewsDetailPresenter } from './presenter';
import { NotFoundError } from '../Error';

export const NewsDetail: React.VFC = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [init, setInit] = React.useState(false);

  const dispatch = useAppDispatch();
  const state = useAppSelector((s) => s);
  const status = NewsState.selectors.selectStatus(state);
  const newsList = NewsState.selectors.selectAll(state.news);

  const news = React.useMemo(
    () => newsList.find((item) => item.id === id),
    [newsList, id],
  );

  React.useEffect(() => {
    if (!init && newsList.length === 0) {
      dispatch(NewsState.actions.list());
    }
    setInit(true);
  }, [dispatch, init, newsList, setInit]);

  if (status !== 'loading' && !news) {
    return <NotFoundError />;
  }

  return (
    <>
      <Helmet>
        <title>
          {ScreenService.getDocumentTitle(
            news?.title || t('ui.pages.NewsDetail.title'),
          )}
        </title>
      </Helmet>
      <NewsDetailPresenter news={news} />
    </>
  );
};
