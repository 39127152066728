import React from 'react';
import { Theme } from 'ui/theme';

export const Header: React.VFC = () => {
  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        backgroundImage: 'url("/images/header-background.png")',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          alignSelf: 'stretch',
          alignItems: 'center',
          justifyContent: 'center',
          padding: 16,
        }}
      >
        <img
          alt=""
          src="/images/logo-small.svg"
        />
      </div>
    </div>
  );
};
