import { css } from '@emotion/react';
import theme from 'ui/theme';

export const linkStyle = css({
  color: theme.color.primary,
  fontSize: 14,
});

export const linkBoldStyle = css({
  color: theme.color.primary,
  fontSize: 14,
  fontWeight: 'bold',
});

export const rightStyle = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
});

export const touchableOpacityStyle = css({
  cursor: 'pointer',
  '&:active': {
    opacity: 0.4,
  },
});

export const touchableLinkStyle = css([
  touchableOpacityStyle,
  {
    textDecoration: 'none',
    '&:active': {
      color: 'inherit',
    },
    '&:visited': {
      color: 'inherit',
    },
  },
]);
