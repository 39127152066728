import { DataPartner } from '../../models';
import { ApiClient } from '../apiClient';
import Axios from 'axios';
import { ApiError } from '../errors';

export class DataPartnerApi extends ApiClient {
  async list(): Promise<DataPartner[]> {
    try {
      const response = await this._client.get<{
        dataPartners: DataPartner[];
      }>('/datapartners');
      return response.data.dataPartners;
    } catch (e) {
      if (Axios.isAxiosError(e)) {
        throw new ApiError(e.response?.status);
      }
      throw e;
    }
  }
}
