import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { ScreenService } from 'core/services';
import { StartPresenter } from './presenter';

export const Start: React.VFC = () => {
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <title>
          {ScreenService.getDocumentTitle(t('ui.pages.Start.title'))}
        </title>
      </Helmet>
      <StartPresenter />
    </>
  );
};
