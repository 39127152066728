import { createSlice } from '@reduxjs/toolkit';
import { ProcessStatus } from 'states';

interface AccountState {
  status: ProcessStatus;
}

const initialState: AccountState = {
  status: 'idle',
};

const slice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    resetStatus(state) {
      state.status = 'idle';
    },
    deleteAccount(state) {
      state.status = 'loading';
    },
    deleteAccountSuccess(state) {
      state.status = 'success';
    },
    deleteAccountFailure(state) {
      state.status = 'failure';
    },
  },
});

export const actions = slice.actions;
export default slice;
