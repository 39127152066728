import { ApiClient } from '../apiClient';
import Axios from 'axios';
import { ApiError } from '../errors';

export interface SignOutResponse {
  userId: string;
  message: string;
}

export class SignOutApi extends ApiClient {
  async signOut(): Promise<SignOutResponse> {
    try {
      const response = await this._client.delete<SignOutResponse>(
        '/user/signout',
      );
      return response.data;
    } catch (e) {
      if (Axios.isAxiosError(e)) {
        throw new ApiError(e.response?.status);
      }
      throw e;
    }
  }
}
