import Axios from 'axios';
import { ApiClient } from '../apiClient';
import { ApiError } from '../errors';
import { AuthResponse, SSORequest } from './types';

export class SignUpApi extends ApiClient {
  async signUp(request: SSORequest): Promise<AuthResponse> {
    try {
      const response = await this._client.post<AuthResponse>(
        '/user/signup',
        request,
      );
      return response.data;
    } catch (e) {
      if (Axios.isAxiosError(e)) {
        throw new ApiError(e.response?.status);
      }
      throw e;
    }
  }
}
