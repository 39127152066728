import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ScreenService } from 'core/services';
import * as authState from 'states/auth';
import { SettingsPresenter } from './presenter';

export const Settings: React.VFC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onClickSignOut = () => {
    dispatch(authState.actions.signOut());
  };

  return (
    <>
      <Helmet>
        <title>{ScreenService.getDocumentTitle(t('global.menu'))}</title>
      </Helmet>
      <SettingsPresenter onClickSignOut={onClickSignOut} />
    </>
  );
};
