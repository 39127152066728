import React from 'react';
import { useTranslation } from 'react-i18next';
import { MdHomeFilled, MdMenu } from 'react-icons/md';
import { css } from '@emotion/react';
import { LinkView } from 'ui/components/shared';
import { touchableOpacityStyle } from 'ui/styles';
import { Theme } from 'ui/theme';
import { NavigationItem } from '../types';

const styles = {
  tab: css({
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  }),

  tabContent: css({
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 8,
  }),
};

interface TabIconProps {
  active: boolean;
}

const HomeTabIcon: React.VFC<TabIconProps> = ({ active }) => (
  <MdHomeFilled
    css={(theme: Theme) => [
      {
        color: theme.color[active ? 'primary' : 'iconInactive'],
        fontSize: theme.iconSize.md,
      },
    ]}
  />
);

const SettingsIcon: React.VFC<TabIconProps> = ({ active }) => (
  <MdMenu
    css={(theme: Theme) => [
      {
        color: theme.color[active ? 'primary' : 'iconInactive'],
        fontSize: theme.iconSize.md,
      },
    ]}
  />
);

interface TabProps {
  active: boolean;
  icon: React.ReactElement;
  title?: string;
  path?: string;
}

const Tab: React.VFC<TabProps> = ({ active, icon, title, path }) => {
  return (
    <LinkView
      css={[styles.tab, active ? undefined : touchableOpacityStyle]}
      to={path ?? ''}
    >
      <div css={styles.tabContent}>
        {icon}
        <div
          css={(theme: Theme) => ({
            color: theme.color[active ? 'primary' : 'iconInactive'],
            fontSize: 10,
            fontWeight: 'bold',
          })}
        >
          {title}
        </div>
      </div>
    </LinkView>
  );
};

export interface BottomNavigationProps {
  active?: NavigationItem;
}

export const BottomNavigation: React.FC<BottomNavigationProps> = ({
  active,
}) => {
  const { t } = useTranslation();

  return (
    <div
      css={(theme: Theme) => ({
        display: 'flex',
        borderTop: `1px solid ${theme.color.divider}`,
      })}
    >
      <Tab
        active={active === 'home'}
        title={t('global.home')}
        icon={<HomeTabIcon active={active === 'home'} />}
        path="/"
      />
      <Tab
        active={active === 'settings'}
        title={t('global.menu')}
        icon={<SettingsIcon active={active === 'settings'} />}
        path="/settings"
      />
    </div>
  );
};
