import Tooltip from 'rc-tooltip';
import React from 'react';
import { MdError } from 'react-icons/md';
import { Theme } from 'ui/theme';

export interface HelpIconProps {
  help?: string | React.ReactElement;
  testId?: string;
}

export const HelpIcon: React.VFC<HelpIconProps> = ({ help, testId }) => {
  return (
    <>
      <Tooltip
        overlay={<span>{help}</span>}
        placement="top"
        trigger={['click', 'hover']}
      >
        <div data-testid={testId} css={{ height: 16, padding: 4 }}>
          <MdError
            css={(theme: Theme) => ({
              color: theme.color.primary,
              fontSize: theme.iconSize.sm,
            })}
          />
        </div>
      </Tooltip>
    </>
  );
};
