import React from 'react';
import { Link as RRLink, LinkProps as RRLinkProps } from 'react-router-dom';
import { Color, FontSize, Theme } from 'ui/theme';

export interface LinkProps extends RRLinkProps {
  color?: Color;
  size?: FontSize | number;
  bold?: boolean;
}

export const Link: React.FC<LinkProps> = ({ color, size, bold, ...props }) => {
  return (
    <RRLink
      css={(theme: Theme) => ({
        color: theme.color[color ?? 'primary'],
        cursor: 'pointer',
        fontSize:
          typeof size === 'number' ? size : theme.fontSize[size ?? 'md'],
        fontWeight: bold ? 'bold' : undefined,
        textDecorationColor: theme.color[color ?? 'primary'],
        textDecorationLine: 'underline',
        textDecorationStyle: 'solid',
        '&:active': {
          opacity: 0.4,
        },
      })}
      {...props}
    />
  );
};
