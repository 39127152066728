const config = {
  neoXApiUrl: process.env.REACT_APP_NEOX_API_URL ?? '',
  aboutPageUrl: process.env.REACT_APP_ABOUT_PAGE_URL ?? '',
  newsJsonFileUrl: process.env.REACT_APP_NEWS_JSON_FILE_URL ?? '',
  faqJsonFileUrl: process.env.REACT_APP_FAQ_JSON_FILE_URL ?? '',
  contactFaqId: process.env.REACT_APP_CONTACT_FAQ_ID ?? '',
  ssnbSsoLoginPageUrl: process.env.REACT_APP_SSNB_SSO_LOGIN_PAGE_URL ?? '',
  faqUrl: process.env.REACT_APP_FAQ_URL ?? '',
  contactsUrl: process.env.REACT_APP_CONTACTS_URL ?? '',
  termsOfServiceUrl: process.env.REACT_APP_TERMS_OF_SERVICE_URL ?? '',
  privacyPolicyUrl: process.env.REACT_APP_PRIVACY_POLICY_URL ?? '',
  licenseNoticeUrl: process.env.REACT_APP_LICENSE_NOTICE_URL ?? '',
};

export default config;
