import React from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import { DataPartner } from 'core/models';
import {
  ConfirmDialog,
  PageHeader,
  Layout,
  Spacer,
  Button,
} from 'ui/components/shared';
import { DataPartnerSection } from './DataPartnerSection';

const containerStyle = css({
  padding: 16,
});

export const DataPartnerDetailPresenter: React.VFC<{
  consented?: boolean;
  dataPartner: DataPartner;
  onPressConsent?: (id: string) => void;
  onPressDissent?: (id: string) => void;
}> = ({ dataPartner, consented, onPressConsent, onPressDissent }) => {
  const { t } = useTranslation();
  const [consentDialogVisible, setConsentDialogVisible] = React.useState(false);
  const [dissentDialogVisible, setDissentDialogVisible] = React.useState(false);

  const handleConsent = () => {
    onPressConsent?.(dataPartner.id);
    setConsentDialogVisible(false);
  };

  const handleDissent = () => {
    onPressDissent?.(dataPartner.id);
    setDissentDialogVisible(false);
  };

  return (
    <>
      <Layout
        activeNavigationItem="home"
        header={<PageHeader title={t(`ui.pages.DataPartnerDetail.title`)} />}
        showNavigator
      >
        <div css={containerStyle}>
          <DataPartnerSection dataPartner={dataPartner} />
          <Spacer space={16} />
          {consented ? (
            <Button color="alert" onClick={() => setDissentDialogVisible(true)}>
              {t('ui.pages.DataPartnerDetail.dissent')}
            </Button>
          ) : (
            <Button
              color="success"
              onClick={() => setConsentDialogVisible(true)}
            >
              {t('ui.pages.DataPartnerDetail.consent')}
            </Button>
          )}
        </div>
      </Layout>
      <ConfirmDialog
        visible={consentDialogVisible}
        title={t('ui.pages.DataPartnerDetail.consentDialogTitle')}
        okTitle={t('ui.pages.DataPartnerDetail.consentDialogOK')}
        cancelTitle={t('ui.pages.DataPartnerDetail.consentDialogCancel')}
        onOK={handleConsent}
        onCancel={() => setConsentDialogVisible(false)}
      />
      <ConfirmDialog
        visible={dissentDialogVisible}
        title={t('ui.pages.DataPartnerDetail.dissentDialogTitle')}
        okTitle={t('ui.pages.DataPartnerDetail.dissentDialogOK')}
        cancelTitle={t('ui.pages.DataPartnerDetail.dissentDialogCancel')}
        onOK={handleDissent}
        onCancel={() => setDissentDialogVisible(false)}
      />
    </>
  );
};
