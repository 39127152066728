import { LOCAL_STORAGE_KEY_SESSION } from 'core/const';

export interface Session {
  idToken: string;
  userId: string;
}

export class AuthService {
  static saveSession(session: Session) {
    const v = JSON.stringify(session);
    localStorage.setItem(LOCAL_STORAGE_KEY_SESSION, v);
  }

  static getSession(): Session | undefined {
    const v = localStorage.getItem(LOCAL_STORAGE_KEY_SESSION);
    return v ? JSON.parse(v) : undefined;
  }

  static removeSession() {
    localStorage.removeItem(LOCAL_STORAGE_KEY_SESSION);
  }

  static currentUser() {
    const s = this.getSession();
    if (typeof s !== 'undefined') {
      return s.userId;
    }
  }

  static currentIdToken() {
    const s = this.getSession();
    if (typeof s !== 'undefined') {
      return s.idToken;
    }
  }
}
