import React from 'react';
import { MdError } from 'react-icons/md';
import { css } from '@emotion/react';
import { Theme } from 'ui/theme';

const styles = {
  container: css({
    display: 'flex',
    flexDirection: 'column',
  }),

  labelContainer: css({
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'stretch',
    marginBottom: 6,
  }),

  bodyContainer: css({
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'stretch',
    alignItems: 'center',
  }),

  inputContainer: css({
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'stretch',
  }),

  errorContainer: css({
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'stretch',
    marginTop: 6,
  }),
};

export interface FormFieldProps {
  control?: React.ReactNode;
  label?: React.ReactNode;
  error?: React.ReactNode;
}

export const FormField: React.VFC<FormFieldProps> = ({
  control,
  error,
  label,
}) => {
  return (
    <div css={styles.container}>
      {label && <div css={styles.labelContainer}>{label}</div>}
      {control && (
        <div css={styles.bodyContainer}>
          <div css={styles.inputContainer}>{control}</div>
          {error && (
            <MdError
              css={(theme: Theme) => ({
                marginLeft: 8,
                color: theme.color.error,
                fontSize: theme.iconSize.sm,
              })}
            />
          )}
        </div>
      )}
      {error && <div css={styles.errorContainer}>{error}</div>}
    </div>
  );
};
