import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { ConsentService, ScreenService } from 'core/services';
import { useAppSelector } from 'states/store';
import * as dataPartnerState from 'states/dataPartner';
import * as consentState from 'states/consent';
import { NotFoundError } from 'ui/pages/Error';
import { DataPartnerDetailPresenter } from './presenter';

export const DataPartnerDetail: React.VFC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isReady, setIsReady] = React.useState([false, false]);
  const dispatch = useDispatch();
  const state = useAppSelector((s) => s);

  const dataPartnerStatus = dataPartnerState.selectors.selectStatus(state);
  const dataPartner = dataPartnerState.selectors.selectById(
    state.dataPartner,
    id ?? '',
  );

  const consentStatus = consentState.selectors.selectStatus(state);
  const consentUpdateStatus = consentState.selectors.selectUpdateStatus(state);
  const consent = consentState.selectors.selectConsent(state);
  const isConsenting = React.useMemo(
    () => (consent ? ConsentService.isConsented(consent, id ?? '') : false),
    [consent, id],
  );

  const onPressConsent = React.useCallback(
    (id: string) => {
      if (consent) {
        const _consent = ConsentService.setPermission(consent, id, 'Yes');
        dispatch(consentState.actions.update({ consent: _consent }));
      }
    },
    [consent, dispatch],
  );

  const onPressDissent = React.useCallback(
    (id: string) => {
      if (consent) {
        const _consent = ConsentService.setPermission(consent, id, 'No');
        dispatch(consentState.actions.update({ consent: _consent }));
      }
    },
    [consent, dispatch],
  );

  React.useEffect(() => {
    dispatch(dataPartnerState.actions.list());
    dispatch(consentState.actions.get());
  }, [dispatch]);

  React.useEffect(() => {
    if (dataPartnerStatus === 'success' || dataPartnerStatus === 'failure') {
      setIsReady([true, isReady[1]]);
    }
  }, [dataPartnerStatus, isReady, setIsReady]);

  React.useEffect(() => {
    if (consentStatus === 'success' || consentStatus === 'failure') {
      setIsReady([isReady[0], true]);
    }
  }, [consentStatus, isReady]);

  React.useEffect(() => {
    if (consent && consentUpdateStatus === 'success') {
      const consented = ConsentService.isConsentedAny(consent);
      navigate(consented ? '/consent-requested' : '/dissent-requested');
    }
  }, [consentUpdateStatus, consent, navigate]);

  if (!isReady[0] || !isReady[1]) {
    return (
      <Helmet>
        <title>{t('ui.pages.DataPartnerDetail.title')}</title>
      </Helmet>
    );
  }

  if (!dataPartner) {
    return <NotFoundError />;
  }

  return (
    <>
      <Helmet>
        <title>
          {ScreenService.getDocumentTitle(
            t('ui.pages.DataPartnerDetail.title'),
          )}
        </title>
      </Helmet>
      <DataPartnerDetailPresenter
        dataPartner={dataPartner}
        consented={isConsenting}
        onPressConsent={onPressConsent}
        onPressDissent={onPressDissent}
      />
    </>
  );
};
