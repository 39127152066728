import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Checkbox,
  FormLabel,
  Layout,
  PageHeader,
  Section,
  Spacer,
  Text,
} from 'ui/components/shared';

export interface DeleteAccountPresenterProps {
  onSubmit?: () => void;
}

export const DeleteAccountPresenter: React.VFC<DeleteAccountPresenterProps> = ({
  onSubmit,
}) => {
  const { t } = useTranslation();
  const [confirmed, setConfirmed] = React.useState(false);

  return (
    <Layout
      activeNavigationItem="home"
      header={<PageHeader title={t('ui.pages.DeleteAccount.title')} />}
      showNavigator
    >
      <div css={{ padding: 16 }}>
        <Section>
          <Text lineHeight={1.4}>{t('ui.pages.DeleteAccount.message')}</Text>
        </Section>
      </div>
      <div css={{ padding: 16 }}>
        <Checkbox
          id={'confirmed'}
          checked={confirmed}
          onChange={(event) => setConfirmed(event.target.checked)}
          label={
            <FormLabel htmlFor="confirmed" bold>
              {t('ui.pages.DeleteAccount.confirmation')}
            </FormLabel>
          }
        />
        <Spacer space={16} />
        <Button color="alert" onClick={onSubmit} disabled={!confirmed}>
          {t('ui.pages.DeleteAccount.submit')}
        </Button>
      </div>
    </Layout>
  );
};
