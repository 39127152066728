import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ScreenService } from 'core/services';
import { useAppDispatch, useAppSelector } from 'states';
import * as AccountState from 'states/account';
import { DeleteAccountPresenter } from './presenter';

export const DeleteAccount: React.VFC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const state = useAppSelector((s) => s);
  const accountStatus = AccountState.selectors.selectStatus(state);

  const navigate = useNavigate();

  const handleSubmit = React.useCallback(() => {
    dispatch(AccountState.actions.deleteAccount());
  }, [dispatch]);

  React.useEffect(() => {
    if (accountStatus === 'success') {
      navigate('/account/delete/complete');
    }
  }, [navigate, accountStatus]);

  return (
    <>
      <Helmet>
        <title>
          {ScreenService.getDocumentTitle(t('ui.pages.DeleteAccount.title'))}
        </title>
      </Helmet>
      <DeleteAccountPresenter onSubmit={handleSubmit} />
    </>
  );
};
