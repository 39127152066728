import Axios from 'axios';
import { ApiClient } from '../apiClient';
import { ApiError } from '../errors';
import { AuthResponse, SSORequest } from './types';

export class SignInApi extends ApiClient {
  async signIn(request: SSORequest): Promise<AuthResponse> {
    try {
      const response = await this._client.post<AuthResponse>(
        '/user/signin',
        request,
      );
      return response.data;
    } catch (e) {
      if (Axios.isAxiosError(e)) {
        const data = e.response?.data;
        if (
          e.response?.status === 401 &&
          typeof data === 'object' &&
          (data.error || data.message)
        ) {
          throw new ApiError(e.response?.status, data.message || data.error);
        }
        throw new ApiError(e.response?.status);
      }
      throw e;
    }
  }
}
