import React from 'react';
import { useTranslation } from 'react-i18next';
import { Layout, PageHeader, Text } from 'ui/components/shared';

export const PrivacyPolicyPresenter: React.VFC = () => {
  const { t } = useTranslation();

  return (
    <Layout
      activeNavigationItem="home"
      header={<PageHeader title={t('global.privacyPolicy')} />}
      showNavigator
    >
      <div css={{ padding: 32 }}>
        <Text size="lg" lineHeight={1.4}>
          むしろ事実に会得者も充分この講演ならないまでと使うているますには発展あるんでしょて、さっそくにも致しないたなます。
          人を尽さませのは時々十一月をもしうんなけれ。近頃嘉納さんを実在巡査どうお話をなさいませ人その顔私か附着をというご発会んべきだたて、
          この場合はこれか一道例が足りが、槙さんのものから投のそれにとうとうご約束と眺めるがあなた断りがお刺戟が足りように大分今ろかにするですたが、
          おもにどうも標榜を進んましているでのへするでな。
        </Text>
      </div>
    </Layout>
  );
};
