import React from 'react';
import { useTranslation } from 'react-i18next';
import { MdOpenInNew } from 'react-icons/md';
import config from 'core/config';
import {
  AboutImage,
  Button,
  Layout,
  LinkText,
  Spacer,
  Text,
  TopHeader,
} from 'ui/components/shared';
import { mediaQueries, Theme } from 'ui/theme';

const StartButton = Button.withComponent('a');

const AboutButton = () => {
  const { t } = useTranslation();
  return (
    <LinkText
      css={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textDecoraton: 'none',
        textDecorationLine: 'none',
      }}
      href={config.aboutPageUrl}
      target="_blank"
    >
      <Text bold color="primary" size="md">
        {t('ui.pages.Start.about')}
      </Text>
      <MdOpenInNew
        css={(theme: Theme) => ({
          marginLeft: 8,
          color: theme.color.primary,
          fontSize: theme.iconSize.md,
        })}
      />
    </LinkText>
  );
};

export const StartPresenter: React.VFC = () => {
  const { t } = useTranslation();
  return (
    <Layout>
      <TopHeader>
        <img
          css={{
            objectFit: 'contain',
            width: '80%',
            [mediaQueries.lg]: { width: '40%' },
          }}
          alt="Themix Personal"
          src="/images/logo.svg"
        />
      </TopHeader>
      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: 16,
        }}
      >
        <AboutImage />
        <Spacer space={16} />
        <AboutButton />
      </div>
      <div css={{ padding: 16 }}>
        <StartButton href={config.ssnbSsoLoginPageUrl}>
          {t(`ui.pages.Start.submit`)}
        </StartButton>
        <div
          css={{ display: 'flex', justifyContent: 'flex-end', marginTop: 4 }}
        >
          <Text color="primary" size="sm">
            {t('ui.pages.Start.caveat')}
          </Text>
        </div>
        <Spacer space={16} />
        <Text color="textSecondary" size="sm" lineHeight={1.5}>
          {t('ui.pages.Start.annotation1')}
        </Text>
        <Spacer space={4} />
        <Text color="textSecondary" size="sm" lineHeight={1.5}>
          {t('ui.pages.Start.annotation2')}
        </Text>
        <Spacer space={4} />
        <Text color="textSecondary" size="sm" lineHeight={1.5}>
          {t('ui.pages.Start.annotation3')}
        </Text>
      </div>
    </Layout>
  );
};
