import styled from '@emotion/styled';

export const FormInput = styled.input(({ theme }) => ({
  appearance: 'none',
  display: 'block',
  border: `1px solid ${theme.color.inputBorder}`,
  borderRadius: 4,
  padding: '20px 12px',
  color: theme.color.textPrimary,
  fontSize: 16,
  '&:focus': {
    outline: 'none',
    border: `2px solid ${theme.color.primary}`,
    boxSizing: 'border-box',
  },
}));
