import React from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useSearchParams, Navigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ApiError } from 'core/api';
import { QUERY_PARAM_CRYPTOGRAM } from 'core/const';
import { ScreenService } from 'core/services';
import { useAppDispatch, useAppSelector } from 'states';
import * as authState from 'states/auth';
import { ToastLinkText } from 'ui/components/shared';
import { SignUpPresenter } from './presenter';

export const SignUp: React.VFC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();
  const [crypto] = React.useState(searchParams.get(QUERY_PARAM_CRYPTOGRAM));
  const error = useAppSelector(authState.selectors.selectError);

  React.useEffect(() => {
    const filteredParams = Array.from(searchParams).filter(
      ([k, _]) => k !== QUERY_PARAM_CRYPTOGRAM,
    );
    setSearchParams(new URLSearchParams(filteredParams));
  }, [searchParams, setSearchParams]);

  const signUp = React.useCallback(() => {
    if (crypto) {
      dispatch(
        authState.actions.signUp({
          type: 'ssnb_sso',
          cryptogram: crypto,
        }),
      );
    }
  }, [crypto, dispatch]);

  React.useEffect(() => {
    if (error instanceof ApiError) {
      switch (error.statusCode) {
        case 401:
          toast.error(
            <div>
              <span>{t('ui.pages.SignUp.unauthorizedError1')}</span>
              <br />
              <ToastLinkText href="/start">
                {t('ui.pages.SignUp.unauthorizedError2')}
              </ToastLinkText>
              <span>{t('ui.pages.SignUp.unauthorizedError3')}</span>
            </div>,
          );
          break;
        case 409:
          toast.error(
            <div>
              <span>{t('ui.pages.SignUp.accountAlreadyInUse1')}</span>
              <br />
              <ToastLinkText href="/start">
                {t('ui.pages.SignUp.accountAlreadyInUse2')}
              </ToastLinkText>
              <span>{t('ui.pages.SignUp.accountAlreadyInUse3')}</span>
            </div>,
          );
      }
    }
  }, [error, navigate, t]);

  if (!crypto) {
    return <Navigate to="/" />;
  }

  return (
    <>
      <Helmet>
        <title>
          {ScreenService.getDocumentTitle(t('ui.pages.SignUp.title'))}
        </title>
      </Helmet>
      <SignUpPresenter onSignUp={signUp} />
    </>
  );
};
