import React from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { AuthService, ScreenService } from 'core/services';
import { NotFoundErrorPresenter } from './presenter';

export const NotFoundError: React.VFC = () => {
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <title>
          {ScreenService.getDocumentTitle(t('ui.pages.Error.NotFound.title'))}
        </title>
      </Helmet>
      <NotFoundErrorPresenter showNavigator={!!AuthService.getSession()} />
    </>
  );
};
