import dayjs from 'dayjs';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { News } from 'core/models';
import {
  Divider,
  Link,
  Section,
  SectionTitle,
  Text,
} from 'ui/components/shared';

export const NewsSection: React.VFC<{
  newsList: News[];
}> = ({ newsList }) => {
  const { t } = useTranslation();

  return (
    <Section>
      <SectionTitle
        title={t('ui.pages.Home.notificationTitle')}
        help={t('ui.pages.Home.notificationHelp')}
        helpTooltipId="notification"
      />
      {newsList.map((item) => (
        <div key={item.id} css={{ marginTop: 16 }}>
          <Link to={`/news/${item.id}`}>{item.title}</Link>
          <div css={{ marginTop: 8, marginBottom: 8 }}>
            <Text size="sm" color="textSecondary">
              {dayjs(item.publicationPeriodFrom).format('YYYY/MM/DD HH:mm')}
            </Text>
          </div>
          <Divider />
        </div>
      ))}
    </Section>
  );
};
